import { z } from 'zod';

import { zodDateOrString } from '../../utils';

/**
 * Chapter 13 Collected Data
 */
export const Chapter13StepFilingStayCollectedData = z.object({
  type: z.literal('chapter13StepFilingStayCollectedData'),
  filingType: z.enum(['joint', 'individual']),
  petitionDate: zodDateOrString,
});

export const Chapter13StepAttorneyReferralCollectedData = z.object({
  type: z.literal('chapter13StepAttorneyReferralCollectedData'),
  contactDate: zodDateOrString,
  lawFirm: z.string(),
  attorneyContactName: z.string(),
  attorneyPhone: z.string(),
  barDate: zodDateOrString,
});

export const Chapter13StepCreditorMeetingCollectedData = z.object({
  type: z.literal('chapter13StepCreditorMeetingCollectedData'),
  meetingDate: zodDateOrString,
});

export const Chapter13StepPlanInPlaceCollectedData = z.object({
  type: z.literal('chapter13StepPlanInPlaceCollectedData'),
  planType: z.string(),
  planExpirationDate: zodDateOrString,
  planAdditionalDetails: z.string(),
});

export const Chapter13StepCollectedData = z.discriminatedUnion('type', [
  Chapter13StepFilingStayCollectedData,
  Chapter13StepAttorneyReferralCollectedData,
  Chapter13StepCreditorMeetingCollectedData,
  Chapter13StepPlanInPlaceCollectedData,
]);
export type Chapter13StepCollectedData = z.infer<typeof Chapter13StepCollectedData>;
