import { compact, flattenDeep, pick, pickBy, range, round, values } from 'lodash';
import { DateTime } from 'luxon';
import * as z from 'zod';

import { convertStringToDate, CreateLoanRow, isConstructionLoan } from '@willow/types-iso';

import { add, addPrecision } from '../../../utils/math';
import { Sanitizer } from '../../shared/sanitizeRequest';
import { validateArmFields } from './crossFieldParsers/validateArmFields';
import { validateEmailAddresses } from './crossFieldParsers/validateEmailAddresses';

export type CrossFieldParserContext = 'api' | 'csv';

const CACHE = new Map<string, any>();

// --- REFINEMENTS --- //

// UTILITY FOR PARSING JUST THE FIELDS WE NEED
// Otherwise, the refinements may fail for reasons unrelated to user-facing the error message
// If this function returns false, the refinement must exit early.
export const getParsedFields = <T, K extends keyof CreateLoanRow>(data: T, fields: K[]) => {
  const zodPickArgs: { [x in K]?: boolean } = {};
  for (const field of fields) {
    zodPickArgs[field] = true;
  }

  const partial = pick(data, fields);

  const sanitized = Sanitizer.safeParse(partial);

  if (!sanitized.success) {
    return false;
  }

  const schemaCacheKey = fields.sort().join('-');
  let parser = CACHE.get(schemaCacheKey);
  if (!parser) {
    // @ts-ignore
    parser = CreateLoanRow.pick(zodPickArgs);
    CACHE.set(schemaCacheKey, parser);
  }

  const parsed = parser.safeParse(sanitized.data);

  if (!parsed.success) {
    return false;
  }

  return parsed.data;
};

const validateIdFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'loanId',
    'poolId',
    'previousServicerId',
    'capitalPartnerId',
    'fullId',
    'otherId1',
    'otherId2',
    'otherId3',
    'ownerId',
  ]);
  if (!parsed) {
    return;
  }

  for (const [key] of Object.entries(parsed)) {
    // Note: excel is sometimes changing id fields to scientific format (ie 20+15243)
    if (parsed[key] && parsed[key].indexOf('+') >= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'ID contains invalid "+" character',
        path: [key],
      });
    }
  }
};

const dateFieldsAndLoanTermMatch: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['firstPaymentDate', 'loanMaturityDate', 'loanTermMonths', 'loanTermYears']);

  // Don't continue if any of the fields failed validation
  // Those errors bubble up to the client so they can fix
  if (!parsed) {
    return;
  }

  if (!parsed.loanTermMonths && !parsed.loanTermYears) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Either `loanTermMonths` or `loanTermYears` must be provided.',
    });
    return;
  }

  const termInMonths = parsed.loanTermMonths ? Number(parsed.loanTermMonths) : Number(parsed.loanTermYears) * 12;
  const expectedMaturityDate = DateTime.fromJSDate(convertStringToDate(parsed.firstPaymentDate))
    .plus({ months: termInMonths - 1 }) // subtract one month since a loan starting on 5/1/22 with a term of 30 years will have a maturity date of 4/1/52
    .startOf('day')
    .toISODate();
  const formattedLoanMaturityDate = DateTime.fromJSDate(convertStringToDate(parsed.loanMaturityDate))
    .startOf('day')
    .toISODate();

  if (expectedMaturityDate !== formattedLoanMaturityDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan maturity date does not match the first payment date + loan term.',
      path: ['loanMaturityDate'],
    });
  }
};

const hasPaymentFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'loanType',
    'productType',
    'constructionLoanType',
    'firstPaymentPrincipalAndInterest',
    'firstPaymentPrincipal',
    'firstPaymentInterest',
    'drawsEnabled',
  ]);

  if (!parsed) {
    return;
  }

  // We dont care about this validation for construction loans or draw enabled loans
  if (
    isConstructionLoan(parsed.constructionLoanType) ||
    parsed.productType === 'businessPurpose' ||
    parsed.productType === 'bridge' ||
    parsed.drawsEnabled
  ) {
    return;
  }

  if (!parsed.firstPaymentPrincipalAndInterest) {
    if (!parsed.firstPaymentPrincipal && !parsed.firstPaymentInterest) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Missing required field for payment. Must include either `firstPaymentPrincipalAndInterest` or `firstPaymentPrincipal` and `firstPaymentInterest`',
      });
    } else if (parsed.firstPaymentPrincipal == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Missing required field `firstPaymentPrincipal`',
      });
    } else if (parsed.firstPaymentInterest == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Missing required field `firstPaymentInterest`',
      });
    }
  }
};

const checkFirstCollectedPaymentDate: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['firstCollectedPaymentDate', 'firstPaymentDate']);

  // Only run this validator if first collected payment date exists
  if (!parsed || !parsed.firstCollectedPaymentDate) {
    return;
  }

  const firstCollectedPaymentDate = convertStringToDate(parsed.firstCollectedPaymentDate);
  const firstPaymentDate = convertStringToDate(parsed.firstPaymentDate);

  if (firstCollectedPaymentDate < firstPaymentDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: '`firstCollectedPaymentDate` cannot be before `firstPaymentDate`',
      path: ['firstCollectedPaymentDate'],
    });
  }
};

const hasDrawFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'drawsEnabled',
    'maxLineOfCredit',
    'drawTerm',
    'drawExpirationDate',
    'repayBeginDate',
  ]);

  if (!parsed) {
    return;
  }

  if (parsed.drawsEnabled && !parsed.maxLineOfCredit) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing required field `maxLineOfCredit` when `drawsEnabled` = true',
      path: ['drawsEnabled'],
    });
  }

  if (!parsed.drawsEnabled) {
    if (parsed.drawTerm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Set `drawsEnabled` = true when setting `drawTerm`',
        path: ['drawTerm'],
      });
    }

    if (parsed.drawExpirationDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Set `drawsEnabled` = true when setting `drawExpirationDate`',
        path: ['drawExpirationDate'],
      });
    }

    if (parsed.repayBeginDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Set `drawsEnabled` = true when setting `repayBeginDate`',
        path: ['repayBeginDate'],
      });
    }
  }
};

const hasInterestOnlyFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['interestOnlyFlag', 'interestOnlyTermMonths', 'interestOnlyTermYears']);

  if (!parsed) {
    return;
  }

  if (parsed.interestOnlyFlag && !(parsed.interestOnlyTermMonths || parsed.interestOnlyTermYears)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Missing required field `interestOnlyTermMonths` or `interestOnlyTermYears` when `interestOnlyFlag` = true',
      path: ['interestOnlyFlag'],
    });
  }
};

const hasEscrowFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['isEscrowed', 'monthlyPaymentEscrow']);

  if (!parsed) {
    return;
  }

  if (parsed.isEscrowed && !parsed.monthlyPaymentEscrow) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing required field = `monthlyPaymentEscrow` when `isEscrowed` = true',
      path: ['isEscrowed'],
    });
  }
};

const hasActiveLoanFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'constructionLoanType',
    'currentOutstandingAmount',
    'firstCollectedPaymentDate',
    'initialInterestBalance',
    'firstPaymentDate',
    'productType',
    'minMonthlyPayment',
  ]);

  if (!parsed) {
    return;
  }

  const isConstructionLoanType =
    isConstructionLoan(parsed.constructionLoanType) ||
    parsed.productType === 'businessPurpose' ||
    parsed.productType === 'bridge';

  const REQUIRED_ACTIVE_LOAN_FIELDS: (keyof CreateLoanRow)[] = compact([
    'currentOutstandingAmount',
    'firstCollectedPaymentDate',
    !isConstructionLoanType ? 'initialInterestBalance' : undefined,
  ]);

  // If the first payment date is in the past, check if firstCollectedPaymentDate is present.
  const firstPaymentDateIsInPast =
    DateTime.fromJSDate(new Date(parsed.firstPaymentDate)).startOf('day') < DateTime.now().startOf('day');

  if (firstPaymentDateIsInPast && !parsed.firstCollectedPaymentDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Active loan is missing required field "firstCollectedPaymentDate". (Active loan is denoted by "firstPaymentDate" in the past)',
    });

    return;
  }

  // If "firstCollectedPaymentDate" is same as "firstPaymentDate", we don't need the other active loan fields
  // because nothing has happened to the loan yet.
  const hasActiveFirstCollectedPaymentDate =
    parsed.firstCollectedPaymentDate &&
    DateTime.fromJSDate(new Date(parsed.firstCollectedPaymentDate)).startOf('day') >
      DateTime.fromJSDate(new Date(parsed.firstPaymentDate)).startOf('day');

  const isActive = parsed.currentOutstandingAmount || hasActiveFirstCollectedPaymentDate;

  if (!isActive) {
    return;
  }

  const missingFields: (keyof CreateLoanRow)[] = [];

  for (const key of REQUIRED_ACTIVE_LOAN_FIELDS) {
    // @ts-ignore
    if (parsed[key] == null) {
      missingFields.push(key);
    }
  }

  if (missingFields.length) {
    const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Active loan is missing required ${
        missingFields.length > 1 ? 'fields' : 'field'
      } ${missingFieldsString}. (Active loan is denoted by presence of \`currentOutstandingAmount\` or \`firstCollectedPaymentDate\`)`,
    });
  }
};

const REQUIRED_BORROWER_MAILING_ADDRESS_FIELDS: (keyof CreateLoanRow)[] = [
  'primaryBorrowerMailingAddressLine1',
  'primaryBorrowerMailingAddressLocality',
  'primaryBorrowerMailingAddressRegion',
  'primaryBorrowerMailingAddressPostcode',
];

const hasBorrowerMailingAddressFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, REQUIRED_BORROWER_MAILING_ADDRESS_FIELDS);

  if (!parsed) {
    return;
  }

  const hasMailingAddress = REQUIRED_BORROWER_MAILING_ADDRESS_FIELDS.some((key) => parsed[key] != null);

  if (!hasMailingAddress) {
    return;
  }

  const missingFields: (keyof CreateLoanRow)[] = [];

  for (const key of REQUIRED_BORROWER_MAILING_ADDRESS_FIELDS) {
    // @ts-ignore
    if (parsed[key] == null) {
      missingFields.push(key);
    }
  }

  if (missingFields.length) {
    const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Borrower mailing address is missing required ${
        missingFields.length > 1 ? 'fields' : 'field'
      } ${missingFieldsString}.`,
    });
  }
};

const REQUIRED_ENTITY_FIELDS: (keyof CreateLoanRow)[] = [
  'entityName',
  'entityEin',
  'entityAddressLine1',
  'entityAddressLocality',
  'entityAddressRegion',
  'entityAddressPostcode',
];

const hasEntityFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'entityCompanyId',
    'entityName',
    'entityEin',
    'entityAddressLine1',
    'entityAddressLocality',
    'entityAddressRegion',
    'entityAddressPostcode',
  ]);

  if (!parsed) {
    return;
  }

  const hasEntity = parsed.entityCompanyId || parsed.entityName;

  if (!hasEntity) {
    return;
  }

  const missingFields: (keyof CreateLoanRow)[] = [];

  for (const key of REQUIRED_ENTITY_FIELDS) {
    // @ts-ignore
    if (parsed[key] == null) {
      missingFields.push(key);
    }
  }

  if (missingFields.length) {
    const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Entity missing required ${
        missingFields.length > 1 ? 'fields' : 'field'
      } ${missingFieldsString}. (Entity is denoted by presence of \`entityCompanyId\` or \`entityName\`)`,
    });
  }
};

const checkPaymentTotal: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'firstPaymentTotal',
    'firstPaymentPrincipal',
    'firstPaymentPrincipalAndInterest',
    'firstPaymentInterest',
    'monthlyPaymentEscrow',
  ]);

  if (!parsed) {
    return;
  }

  // Default to zero. (other parsers validate presence)
  const {
    firstPaymentTotal = 0,
    firstPaymentPrincipal = 0,
    firstPaymentPrincipalAndInterest = 0,
    firstPaymentInterest = 0,
    monthlyPaymentEscrow = 0,
  } = parsed;

  // only check total if it exists
  if (!firstPaymentTotal) {
    return;
  }

  const principalAndInterest =
    firstPaymentPrincipalAndInterest > 0
      ? firstPaymentPrincipalAndInterest
      : add(firstPaymentPrincipal, firstPaymentInterest);

  if (add(principalAndInterest, monthlyPaymentEscrow) !== firstPaymentTotal) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Amounts provided for principal, interest, and escrow do not equal the total payment amount',
      path: ['firstPaymentTotal'],
    });
  }
};

const checkRemittanceFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'investorRemittanceRate',
    'servicerRemittanceRate',
    'investor2RemittanceRate',
    'loanInterestRateDecimal',
    'loanInterestRatePercent',
    'investorOwnershipPercent',
    'investor2OwnershipPercent',
  ]);

  if (!parsed) {
    return;
  }

  const {
    investorRemittanceRate,
    servicerRemittanceRate,
    investor2RemittanceRate,
    loanInterestRateDecimal,
    loanInterestRatePercent,
    investorOwnershipPercent,
    investor2OwnershipPercent,
  } = parsed;

  const shouldCheckRemittanceFields = investorRemittanceRate || servicerRemittanceRate;
  if (!shouldCheckRemittanceFields) {
    return;
  }

  // Ensure both fields are present so we can sum them
  if (investorRemittanceRate == null || servicerRemittanceRate == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Both `investorRemittanceRate` and `servicerRemittanceRate` must be present',
    });
    return;
  }

  const interestRatePercent = loanInterestRatePercent
    ? Number(loanInterestRatePercent)
    : round(Number(loanInterestRateDecimal ?? 0) * 100, 3);

  // Ensure rates add up to total interest rate
  if (
    addPrecision(3, investorRemittanceRate, servicerRemittanceRate, investor2RemittanceRate ?? 0) !==
    interestRatePercent
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Amounts provided for the investor remittance do not equal the interest rate',
    });
  }

  if (
    investorOwnershipPercent &&
    investor2OwnershipPercent &&
    add(investorOwnershipPercent, investor2OwnershipPercent) !== 100
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Amounts provided for the owernship percentage do not add up to 100%',
    });
  }
};

const hasCoBorrowerNameFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const CO_BORROWER_FIELDS_FOR_VALIDATION = range(1, 11)
    .map(
      (n) =>
        [
          `coBorrower${n}FirstName`,
          `coBorrower${n}LastName`,
          `coBorrower${n}Email`,
          `coBorrower${n}Phone`,
        ] as (keyof CreateLoanRow)[],
    )
    .flat();
  const parsed = getParsedFields(data, CO_BORROWER_FIELDS_FOR_VALIDATION);

  if (!parsed) {
    return;
  }

  const missingFields: string[] = [];

  for (const i of range(1, 11)) {
    // @ts-ignore
    if (parsed[`coBorrower${i}Email`] || parsed[`coBorrower${i}Phone`]) {
      for (const key of [`coBorrower${i}FirstName`, `coBorrower${i}LastName`]) {
        // @ts-ignore
        if (parsed[key] == null) {
          missingFields.push(key);
        }
      }
    }
  }

  if (missingFields.length) {
    const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Missing required ${
        missingFields.length > 1 ? 'fields' : 'field'
      } for co-borrowers: ${missingFieldsString}`,
    });
  }
};

const BASE_ESCROW_COUNTY_FIELDS_FOR_VALIDATION = [
  'taxCountyIsEscrowed',
  'taxCountyNextPaymentAmount',
  'taxCountyPayeeName',
  'addressCounty',
] as (keyof CreateLoanRow)[];
const MULTI_PROP_ESCROW_COUNTY_FIELDS_FOR_VALIDATION = range(2, 11)
  .map(
    (n) =>
      [
        `address${n}TaxCountyIsEscrowed`,
        `address${n}TaxCountyNextPaymentAmount`,
        `address${n}TaxCountyPayeeName`,
        `address${n}County`,
      ] as (keyof CreateLoanRow)[],
  )
  .flat()
  .concat(BASE_ESCROW_COUNTY_FIELDS_FOR_VALIDATION);

const hasEscrowCountyFields: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsed =
      context === 'api'
        ? getParsedFields(data, MULTI_PROP_ESCROW_COUNTY_FIELDS_FOR_VALIDATION)
        : getParsedFields(data, BASE_ESCROW_COUNTY_FIELDS_FOR_VALIDATION);

    if (!parsed) {
      return;
    }

    const missingFields: string[] = [];

    if (
      parsed.taxCountyIsEscrowed === true ||
      (parsed.taxCountyNextPaymentAmount != null && parsed.taxCountyNextPaymentAmount > 0) ||
      parsed.taxCountyPayeeName != null
    ) {
      for (const key of [`taxCountyIsEscrowed`, `taxCountyNextPaymentAmount`, `taxCountyPayeeName`]) {
        // @ts-ignore
        if (parsed[key] == null) {
          missingFields.push(key);
        }
      }

      if (!parsed.addressCounty) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Cannot add county taxes without required field: `addressCounty`',
        });
      }
    }

    if (context === 'api') {
      for (const i of range(2, 11)) {
        if (
          // @ts-ignore
          parsed[`address${i}TaxCountyIsEscrowed`] === true ||
          // @ts-ignore
          (parsed[`address${i}TaxCountyNextPaymentAmount`] != null &&
            parsed[`address${i}TaxCountyNextPaymentAmount`] > 0) ||
          // @ts-ignore
          parsed[`address${i}TaxCountyPayeeName`] != null
        ) {
          for (const key of [
            `address${i}TaxCountyIsEscrowed`,
            `address${i}TaxCountyNextPaymentAmount`,
            `address${i}TaxCountyPayeeName`,
          ]) {
            // @ts-ignore
            if (parsed[key] == null) {
              missingFields.push(key);
            }
          }

          if (!parsed[`address${i}County`]) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Cannot add county taxes for address ${i} without required field: \`address${i}County\``,
            });
          }
        }
      }
    }

    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } for escrow county taxes: ${missingFieldsString}`,
      });
    }
  };

const BASE_ESCROW_CITY_FIELDS_FOR_VALIDATION = [
  'taxCityIsEscrowed',
  'taxCityNextPaymentAmount',
  'taxCityPayeeName',
  'taxCityNextDueDate',
  'taxCityPaymentCadence',
] as (keyof CreateLoanRow)[];
const MULTI_PROP_ESCROW_CITY_FIELDS_FOR_VALIDATION = range(2, 11)
  .map(
    (n) =>
      [
        `address${n}TaxCityIsEscrowed`,
        `address${n}TaxCityNextPaymentAmount`,
        `address${n}TaxCityPayeeName`,
        `address${n}TaxCityNextDueDate`,
        `address${n}TaxCityPaymentCadence`,
      ] as (keyof CreateLoanRow)[],
  )
  .flat()
  .concat(BASE_ESCROW_CITY_FIELDS_FOR_VALIDATION);

const hasEscrowCityFields: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsed =
      context === 'api'
        ? getParsedFields(data, MULTI_PROP_ESCROW_CITY_FIELDS_FOR_VALIDATION)
        : getParsedFields(data, BASE_ESCROW_CITY_FIELDS_FOR_VALIDATION);

    if (!parsed) {
      return;
    }

    const missingFields: string[] = [];

    if (
      parsed.taxCityIsEscrowed === true ||
      (parsed.taxCityNextPaymentAmount != null && parsed.taxCityNextPaymentAmount > 0) ||
      parsed.taxCityPayeeName != null
    ) {
      for (const key of [
        `taxCityIsEscrowed`,
        `taxCityNextPaymentAmount`,
        `taxCityPayeeName`,
        `taxCityNextDueDate`,
        `taxCityPaymentCadence`,
      ]) {
        // @ts-ignore
        if (parsed[key] == null) {
          missingFields.push(key);
        }
      }
    }

    if (context === 'api') {
      for (const i of range(2, 11)) {
        if (
          // @ts-ignore
          parsed[`address${i}TaxCityIsEscrowed`] === true ||
          // @ts-ignore
          (parsed[`address${i}TaxCityNextPaymentAmount`] != null &&
            parsed[`address${i}TaxCityNextPaymentAmount`] > 0) ||
          // @ts-ignore
          parsed[`address${i}TaxCityPayeeName`] != null
        ) {
          for (const key of [
            `address${i}TaxCityIsEscrowed`,
            `address${i}TaxCityNextPaymentAmount`,
            `address${i}TaxCityPayeeName`,
          ]) {
            // @ts-ignore
            if (parsed[key] == null) {
              missingFields.push(key);
            }
          }
        }
      }
    }

    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } for escrow city taxes: ${missingFieldsString}`,
      });
    }
  };

const BASE_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION = [
  'taxSchoolIsEscrowed',
  'taxSchoolNextPaymentAmount',
  'taxSchoolPayeeName',
  'taxSchoolNextDueDate',
  'taxSchoolPaymentCadence',
] as (keyof CreateLoanRow)[];
const MULTI_PROP_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION = range(2, 11)
  .map(
    (n) =>
      [
        `address${n}TaxSchoolIsEscrowed`,
        `address${n}TaxSchoolNextPaymentAmount`,
        `address${n}TaxSchoolPayeeName`,
        `address${n}TaxSchoolNextDueDate`,
        `address${n}TaxSchoolPaymentCadence`,
      ] as (keyof CreateLoanRow)[],
  )
  .flat()
  .concat(BASE_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION);

const hasEscrowSchoolFields: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsed =
      context === 'api'
        ? getParsedFields(data, MULTI_PROP_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION)
        : getParsedFields(data, BASE_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION);

    if (!parsed) {
      return;
    }

    const missingFields: string[] = [];

    if (
      parsed.taxSchoolIsEscrowed === true ||
      (parsed.taxSchoolNextPaymentAmount != null && parsed.taxSchoolNextPaymentAmount > 0) ||
      parsed.taxSchoolPayeeName != null
    ) {
      for (const key of [
        'taxSchoolIsEscrowed',
        'taxSchoolNextPaymentAmount',
        'taxSchoolPayeeName',
        'taxSchoolNextDueDate',
        'taxSchoolPaymentCadence',
      ]) {
        // @ts-ignore
        if (parsed[key] == null) {
          missingFields.push(key);
        }
      }
    }

    if (context === 'api') {
      for (const i of range(2, 11)) {
        if (
          // @ts-ignore
          parsed[`address${i}TaxSchoolIsEscrowed`] === true ||
          // @ts-ignore
          (parsed[`address${i}TaxSchoolNextPaymentAmount`] != null &&
            parsed[`address${i}TaxSchoolNextPaymentAmount`] > 0) ||
          // @ts-ignore
          parsed[`address${i}TaxSchoolPayeeName`] != null
        ) {
          for (const key of [
            `address${i}TaxSchoolIsEscrowed`,
            `address${i}TaxSchoolNextPaymentAmount`,
            `address${i}TaxSchoolPayeeName`,
            `address${i}TaxSchoolNextDueDate`,
            `address${i}TaxSchoolPaymentCadence`,
          ]) {
            // @ts-ignore
            if (parsed[key] == null) {
              missingFields.push(key);
            }
          }
        }
      }
    }

    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } for escrow school taxes: ${missingFieldsString}`,
      });
    }
  };

const BASE_ESCROW_OTHER_FIELDS_FOR_VALIDATION = range(1, 4)
  .map((n) => [
    `address1TaxOther${n}IsEscrowed`,
    `address1TaxOther${n}NextPaymentAmount`,
    `address1TaxOther${n}PayeeName`,
    `address1TaxOther${n}NextDueDate`,
    `address1TaxOther${n}PaymentCadence`,
  ])
  .flat(1) as (keyof CreateLoanRow)[];
const MULTI_PROP_ESCROW_OTHER_FIELDS_FOR_VALIDATION = (
  flattenDeep(
    range(2, 11).map((p) =>
      range(1, 4).map((n) => [
        p === 1 ? `taxOther${n}IsEscrowed` : `address${p}TaxOther${n}IsEscrowed`,
        p === 1 ? `taxOther${n}NextPaymentAmount` : `address${p}TaxOther${n}NextPaymentAmount`,
        p === 1 ? `taxOther${n}PayeeName` : `address${p}TaxOther${n}PayeeName`,
        p === 1 ? `taxOther${n}NextDueDate` : `address${p}TaxOther${n}NextDueDate`,
        p === 1 ? `taxOther${n}PaymentCadence` : `address${p}TaxOther${n}PaymentCadence`,
      ]),
    ),
  ) as (keyof CreateLoanRow)[]
).concat(BASE_ESCROW_OTHER_FIELDS_FOR_VALIDATION);

const hasEscrowOtherTaxFields: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsed =
      context === 'api'
        ? getParsedFields(data, MULTI_PROP_ESCROW_OTHER_FIELDS_FOR_VALIDATION)
        : getParsedFields(data, BASE_ESCROW_OTHER_FIELDS_FOR_VALIDATION);

    if (!parsed) {
      return;
    }

    const missingFields: string[] = [];

    for (const n of range(1, 4)) {
      if (
        // @ts-ignore
        parsed[`taxOther${n}IsEscrowed`] === true ||
        // @ts-ignore
        (parsed[`taxOther${n}NextPaymentAmount`] != null && parsed[`taxOther${n}NextPaymentAmount`] > 0) ||
        // @ts-ignore
        parsed[`taxOther${n}PayeeName`] != null
      ) {
        for (const key of [
          `taxOther${n}IsEscrowed`,
          `taxOther${n}NextPaymentAmount`,
          `taxOther${n}PayeeName`,
          `taxOther${n}NextDueDate`,
          `taxOther${n}PaymentCadence`,
        ]) {
          // @ts-ignore
          if (parsed[key] == null) {
            missingFields.push(key);
          }
        }
      }
    }

    if (context === 'api') {
      for (const p of range(2, 11)) {
        for (const n of range(1, 4)) {
          if (
            // @ts-ignore
            parsed[`address${p}TaxOther${n}IsEscrowed`] === true ||
            // @ts-ignore
            (parsed[`address${p}TaxOther${n}NextPaymentAmount`] != null &&
              parsed[`address${p}TaxOther${n}NextPaymentAmount`] > 0) ||
            // @ts-ignore
            parsed[`address${p}TaxOther${n}PayeeName`] != null
          ) {
            for (const key of [
              `address${p}TaxOther${n}IsEscrowed`,
              `address${p}TaxOther${n}NextPaymentAmount`,
              `address${p}TaxOther${n}PayeeName`,
              `address${p}TaxOther${n}NextDueDate`,
              `address${p}TaxOther${n}PaymentCadence`,
            ]) {
              // @ts-ignore
              if (parsed[key] == null) {
                missingFields.push(key);
              }
            }
          }
        }
      }
    }

    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } for escrow other taxes: ${missingFieldsString}`,
      });
    }
  };

const BASE_INSURANCE_FIELDS_FOR_VALIDATION = range(1, 4)
  .map((n) => [
    `insurance${n}IsEscrowed`,
    `insurance${n}Amount`,
    `insurance${n}CompanyName`,
    `insurance${n}PolicyNumber`,
    `insurance${n}PolicyStart`,
    `insurance${n}PolicyEnd`,
  ])
  .flat(1) as (keyof CreateLoanRow)[];
const MULTI_PROP_INSURANCE_FIELDS_FOR_VALIDATION = (
  flattenDeep(
    range(2, 11).map((p) =>
      range(1, 4).map((n) => [
        `address${p}Insurance${n}IsEscrowed`,
        `address${p}Insurance${n}Amount`,
        `address${p}Insurance${n}CompanyName`,
        `address${p}Insurance${n}PolicyNumber`,
        `address${p}Insurance${n}PolicyStart`,
        `address${p}Insurance${n}PolicyEnd`,
      ]),
    ),
  ) as (keyof CreateLoanRow)[]
).concat(BASE_INSURANCE_FIELDS_FOR_VALIDATION);

const hasInsuranceEscrowFields: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsed =
      context === 'api'
        ? getParsedFields(data, MULTI_PROP_INSURANCE_FIELDS_FOR_VALIDATION)
        : getParsedFields(data, BASE_INSURANCE_FIELDS_FOR_VALIDATION);

    const missingFields: string[] = [];

    for (const n of range(1, 4)) {
      if (
        // @ts-ignore
        parsed[`insurance${n}IsEscrowed`] === true ||
        // @ts-ignore
        (parsed[`insurance${n}Amount`] != null && parsed[`insurance${n}Amount`] > 0) ||
        // @ts-ignore
        parsed[`insurance${n}CompanyName`] != null ||
        // @ts-ignore
        parsed[`insurance${n}PolicyNumber`] != null
      ) {
        for (const key of [
          `insurance${n}IsEscrowed`,
          `insurance${n}Amount`,
          `insurance${n}CompanyName`,
          `insurance${n}PolicyNumber`,
          `insurance${n}PolicyStart`,
          `insurance${n}PolicyEnd`,
        ]) {
          // @ts-ignore
          if (parsed[key] == null) {
            missingFields.push(key);
          }
        }
      }
    }

    if (context === 'api') {
      for (const p of range(2, 11)) {
        for (const n of range(1, 4)) {
          if (
            // @ts-ignore
            parsed[`address${p}Insurance${n}IsEscrowed`] === true ||
            // @ts-ignore
            (parsed[`address${p}Insurance${n}Amount`] != null && parsed[`address${p}Insurance${n}Amount`] > 0) ||
            // @ts-ignore
            parsed[`address${p}Insurance${n}CompanyName`] != null ||
            // @ts-ignore
            parsed[`address${p}Insurance${n}PolicyNumber`] != null
          ) {
            for (const key of [
              `address${p}Insurance${n}IsEscrowed`,
              `address${p}Insurance${n}Amount`,
              `address${p}Insurance${n}CompanyName`,
              `address${p}Insurance${n}PolicyNumber`,
              `address${p}Insurance${n}PolicyStart`,
              `address${p}Insurance${n}PolicyEnd`,
            ]) {
              // @ts-ignore
              if (parsed[key] == null) {
                missingFields.push(key);
              }
            }
          }
        }
      }
    }

    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } for insurance escrow item: ${missingFieldsString}`,
      });
    }
  };

const checkReserveFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'isInterestReserve',
    'buydownType',
    'initialReserveBalance',
    'isPendingActivation',
  ]);

  if (!parsed) {
    return;
  }

  if (parsed.isInterestReserve && !parsed.initialReserveBalance && !parsed.isPendingActivation) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan with `isInterestReserve` = true must have `initialReserveBalance` greater than 0.',
      path: ['isInterestReserve'],
    });
  }

  if (parsed.buydownType && !parsed.initialReserveBalance && !parsed.isPendingActivation) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan with `buydownType` must have `initialReserveBalance` greater than 0.',
      path: ['buydownType'],
    });
  }

  if (parsed.initialReserveBalance && !parsed.isInterestReserve && !parsed.buydownType) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Reserve balance will be ignored unless `isInterestReserve` = true or `buydownType` is defined',
      path: ['initialReserveBalance'],
    });
  }
};

const checkDefaultInterestFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'defaultInterestRateDecimal',
    'defaultInterestRatePercent',
    'defaultInterestEffectiveDate',
    'firstPaymentDate',
  ]);

  if (!parsed) {
    return;
  }

  if (parsed.defaultInterestRateDecimal && !parsed.defaultInterestEffectiveDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan with defaultInterestRateDecimal must also have defaultInterestEffectiveDate defined.',
      path: ['defaultInterestRateDecimal'],
    });
  }

  if (parsed.defaultInterestRatePercent && !parsed.defaultInterestEffectiveDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan with defaultInterestRatePercent must also have defaultInterestEffectiveDate defined.',
      path: ['defaultInterestRatePercent'],
    });
  }

  if (new Date(parsed.defaultInterestEffectiveDate) < new Date(parsed.firstPaymentDate)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'defaultInterestEffectiveDate cannot be before firstPaymentDate.',
      path: ['defaultInterestEffectiveDate'],
    });
  }

  if (new Date(parsed.defaultInterestEffectiveDate) > new Date(parsed.maturityDate)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'defaultInterestEffectiveDate cannot be after the.',
      path: ['defaultInterestEffectiveDate'],
    });
  }
};

const deprecateInterestReserveField: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  if (data.initialInterestReserveBalance) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Field is deprecated. Use `initialReserveBalance` instead.',
      path: ['initialInterestReserveBalance'],
    });
  }
};

const hasLoanTypeOrAmortizationTypeField: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['loanType', 'amortizationType']);

  if (!parsed) {
    return;
  }

  if (!parsed.loanType && !parsed.amortizationType) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing required field `amortizationType`.',
    });
  }
};

const hasAcquiredLoanFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, ['isAcquired', 'acquisitionDate', 'firstCollectedPaymentDate']);

  if (!parsed) {
    return;
  }

  if (parsed.isAcquired && !parsed.acquisitionDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Acquired loan must have `acquisitionDate`.',
      path: ['isAcquired'],
    });
  }

  if (parsed.acquisitionDate) {
    if (!parsed.firstCollectedPaymentDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Loan with `acquisitionDate` must have `firstCollectedPaymentDate`.',
        path: ['acquisitionDate'],
      });
    }

    if (
      parsed.firstCollectedPaymentDate &&
      convertStringToDate(parsed.acquisitionDate) > convertStringToDate(parsed.firstCollectedPaymentDate)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '`acquisitionDate` must be before `firstCollectedPaymentDate`.',
        path: ['acquisitionDate'],
      });
    }
  }
};

const hasMonthlyMortgageInsuranceField: (context: CrossFieldParserContext) => z.SuperRefinement<CreateLoanRow> =
  (context: CrossFieldParserContext) => (data, ctx) => {
    const parsedEscrowLines =
      context === 'api'
        ? getParsedFields(data, [
            ...MULTI_PROP_ESCROW_COUNTY_FIELDS_FOR_VALIDATION,
            ...MULTI_PROP_ESCROW_CITY_FIELDS_FOR_VALIDATION,
            ...MULTI_PROP_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION,
            ...MULTI_PROP_ESCROW_OTHER_FIELDS_FOR_VALIDATION,
            ...MULTI_PROP_INSURANCE_FIELDS_FOR_VALIDATION,
          ])
        : getParsedFields(data, [
            ...BASE_ESCROW_COUNTY_FIELDS_FOR_VALIDATION,
            ...BASE_ESCROW_CITY_FIELDS_FOR_VALIDATION,
            ...BASE_ESCROW_SCHOOL_FIELDS_FOR_VALIDATION,
            ...BASE_ESCROW_OTHER_FIELDS_FOR_VALIDATION,
            ...BASE_INSURANCE_FIELDS_FOR_VALIDATION,
          ]);

    const parsedFields = getParsedFields(data, ['monthlyPaymentMi', 'isEscrowed', 'monthlyPaymentEscrow']);

    if (!parsedEscrowLines || !parsedFields) {
      return;
    }

    const isEscrowedFields = pickBy(parsedEscrowLines, (_, key) => key.toLowerCase().includes('isescrowed'));

    const hasEscrowLines = values(isEscrowedFields).some((v) => v != null);
    const isEscrowed =
      values(isEscrowedFields).some((v) => v === true) ||
      parsedFields.isEscrowed === true ||
      !!parsedFields.monthlyPaymentEscrow;

    if (hasEscrowLines && isEscrowed && parsedFields.monthlyPaymentMi == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Escrowed loan must include `monthlyPaymentMi` (mortgage insurance) with a value of 0 or greater.',
      });
    }
  };

export const getCreateLoanCrossFieldParser = (context: CrossFieldParserContext) =>
  z
    .any()
    .superRefine(validateIdFields)
    .superRefine(dateFieldsAndLoanTermMatch)
    .superRefine(hasPaymentFields)
    .superRefine(checkFirstCollectedPaymentDate)
    .superRefine(hasDrawFields)
    .superRefine(hasInterestOnlyFields)
    .superRefine(hasEscrowFields)
    .superRefine(hasActiveLoanFields)
    .superRefine(hasBorrowerMailingAddressFields)
    .superRefine(hasEntityFields)
    .superRefine(hasCoBorrowerNameFields)
    .superRefine(checkPaymentTotal)
    .superRefine(checkRemittanceFields)
    .superRefine(checkReserveFields)
    .superRefine(checkDefaultInterestFields)
    .superRefine(deprecateInterestReserveField)
    .superRefine(hasLoanTypeOrAmortizationTypeField)
    .superRefine(hasAcquiredLoanFields)
    .superRefine(hasEscrowCountyFields(context))
    .superRefine(hasEscrowCityFields(context))
    .superRefine(hasEscrowSchoolFields(context))
    .superRefine(hasEscrowOtherTaxFields(context))
    .superRefine(hasInsuranceEscrowFields(context))
    .superRefine(hasMonthlyMortgageInsuranceField(context))
    .superRefine(validateEmailAddresses)
    .superRefine(validateArmFields);
