import { z } from 'zod';

import { Chapter7StepCollectedData } from './Chapter7CollectedData';
import { Chapter13StepCollectedData } from './Chapter13CollectedData';
import { ForeclosureStepCollectedData } from './ForeclosureCollectedData';
import { ReoStepCollectedData } from './ReoCollectedData';

export const EmptyCollectedData = z.object({
  type: z.literal('empty'),
});
export type EmptyCollectedData = z.infer<typeof EmptyCollectedData>;

export const TaskCollectedData = z.union([
  EmptyCollectedData,
  ForeclosureStepCollectedData,
  ReoStepCollectedData,
  Chapter7StepCollectedData,
  Chapter13StepCollectedData,
]);
export type TaskCollectedData = z.infer<typeof TaskCollectedData>;
