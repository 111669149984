import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const EditBorrowerEvent = BaseLoanEvent.extend({
  type: z.literal('editBorrower'),
  borrower: z.object({
    id: zodBrandedUuid<BorrowerId>(),
    name: z.string(),
  }),
});
export type EditBorrowerEvent = z.infer<typeof EditBorrowerEvent>;
