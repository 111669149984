import * as z from 'zod';

import { AddAccountCommentEvent } from './AddAccountCommentEvent';
import { AddAdditionalBorrowerEvent } from './AddAdditionalBorrowerEvent';
import { AddAuditLogItemEvent } from './AddAuditLogItemEvent';
import { CancelFeeEvent } from './CancelFeeEvent';
import { CancelWorkoutPlanEvent } from './CancelWorkoutPlanEvent';
import { ChangePrimaryBorrowerEvent } from './ChangePrimaryBorrowerEvent';
import { ConstructionToPermanentEvent } from './ConstructionToPermanentEvent';
import { CreateAdvanceEvent, CreateFeeEvent } from './CreateChargeEvent';
import { CreateDisbursementEvent } from './CreateDisbursementEvent';
import { CreateDrawEvent } from './CreateDrawEvent';
import { CreateEscrowSettingEvent } from './CreateEscrowSettingEvent';
import { CreateFeePaymentEvent } from './CreateFeePaymentEvent';
import { CreateForwardPaymentDisbursementEvent } from './CreateForwardPaymentDisbursementEvent';
import { CreatePaymentAfterPayoffDisbursementEvent } from './CreatePaymentAfterPayoffDisbursementEvent';
import { CreatePaymentEvent } from './CreatePaymentEvent';
import { CreatePayoffEvent } from './CreatePayoffEvent';
import { CreatePrincipalPrepaymentEvent } from './CreatePrincipalPrepaymentEvent';
import { CreateRequestedPayoffEvent } from './CreateRequestedPayoffEvent';
import { CreateTransferEvent } from './CreateTransferEvent';
import { CreateWorkoutPlanEvent } from './CreateWorkoutPlanEvent';
import { CreationEvent } from './CreationEvent';
import { DeferDelinquentInterestEvent } from './DeferDelinquentInterestEvent';
import { DeleteDocumentEvent } from './DeleteDocumentEvent';
import { EditBorrowerEvent } from './EditBorrowerEvent';
import { EditEscrowFieldsEvent } from './EditEscrowFieldsEvent';
import { FlagAccountEvent } from './FlagAccountEvent';
import { ForceUpdateEvent } from './ForceUpdateEvent';
import { GenerateGoodbyeLetterPdfEvent } from './GenerateGoodbyeLetterPdfEvent';
import { GenerateNextStatementEvent } from './GenerateNextStatementEvent';
import { MailMonthlyStatementTaskEvent } from './MailMonthlyStatementTaskEvent';
import { ModifyMaturityDateEvent } from './ModifyMaturityDateEvent';
import { ModifyPropertyAddressEvent } from './ModifyPropertyAddressEvent';
import { MoveToTransferEvent } from './MoveToTransferEvent';
import { NextAmortizationMonthEvent } from './NextAmortizationMonthEvent';
import { RecordPayoffEvent } from './RecordPayoffEvent';
import { RegenerateStatementEvent } from './RegenerateStatementEvent';
import { RegenerateStatementPdfEvent } from './RegenerateStatementPdfEvent';
import { RegenerateTransferDocumentsEvent } from './RegenerateTransferDocumentsEvent';
import { RemoveAdditionalBorrowerEvent } from './RemoveAdditionalBorrowerEvent';
import { ReverseDisbursementEvent } from './ReverseDisbursementEvent';
import { ReverseDrawEvent } from './ReverseDrawEvent';
import { ReversePaymentEvent } from './ReversePaymentEvent';
import { SaveDocumentPdfEvent } from './SaveDocumentPdfEvent';
import { SaveStatementPdfEvent } from './SaveStatementPdfEvent';
import { SendEmailEvent } from './SendEmailEvent';
import { SendEscrowCompanyEmailsEvent } from './SendEscrowCompanyEmailsEvent';
import { SendExternalEmailEvent } from './SendExternalEmailEvent';
import { SendFinalPaymentEmailEvent } from './SendFinalPaymentEmailEvent';
import { SendGoodbyeEmailEvent } from './SendGoodbyeEmailEvent';
import { SendGracePastDueEmailEvent } from './SendGracePastDueEmailEvent';
import { SendLatePaymentEmailEvent } from './SendLatePaymentEmailEvent';
import { SendMortgageeLetterEvent } from './SendMortgageeLetterEvent';
import { SendPaymentReminderEmailEvent } from './SendPaymentReminderEmailEvent';
import { SendWelcomeEmailEvent } from './SendWelcomeEmailEvent';
import { SetLoanStatusEvent } from './SetLoanStatusEvent';
import { TransferAccountEvent } from './TransferAccountEvent';
import { UpdateAdvanceInterestEvent } from './UpdateAdvanceInterestEvent';
import { UpdateAutopaySettingsEvent } from './UpdateAutopaySettingsEvent';
import { UpdateBillSettingsEvent } from './UpdateBillSettingsEvent';
import { UpdateDelinquentInterestRateEvent } from './UpdateDelinquentInterestRateEvent';
import { UpdateDocumentBorrowerAccessEvent } from './UpdateDocumentBorrowerAccess';
import { UpdateDocumentTitleEvent } from './UpdateDocumentTitleEvent';
import { UpdateEscrowDueEvent } from './UpdateEscrowDueEvent';
import { UpdateEscrowPaymentEvent } from './UpdateEscrowPaymentEvent';
import { UpdateEscrowSettingEvent } from './UpdateEscrowSettingEvent';
import { UpdateIdsAndRemittanceDataEvent } from './UpdateIdsAndRemittanceDataEvent';
import { UpdateLoanEvent } from './UpdateLoanEvent';
import { UpdateMonthlyInterestRateEvent } from './UpdateMonthlyInterestRateEvent';
import { UpdatePaymentEvent } from './UpdatePaymentEvent';
import { UpdateRemittanceSplitEvent } from './UpdateRemittanceSplitEvent';
import { UpdateSentEmailEvent } from './UpdateSentEmailEvent';
import { UpdateTransferEvent } from './UpdateTransferEvent';
import { UsioMarkAsCancelledEvent } from './UsioMarkAsCancelledEvent';
import { UsioMarkAsFailedEvent } from './UsioMarkAsFailedEvent';
import { UsioMarkAsProcessedEvent } from './UsioMarkAsProcessedEvent';
import { UsioTransactionBatchedEvent } from './UsioTransactionBatchedEvent';
import { WaiveDelinquentInterestEvent } from './WaiveDelinquentInterestEvent';
// TODO: convert this to discriminated union. The problem is that SendEmailEvent is already a union with the same
// type, so this breaks the discriminated union
export const LoanEvent = z.union([
  FlagAccountEvent,
  ForceUpdateEvent,
  CreationEvent,
  TransferAccountEvent,
  CreatePaymentEvent,
  CreateFeePaymentEvent,
  CreatePrincipalPrepaymentEvent,
  CreateTransferEvent,
  UpdatePaymentEvent,
  UpdateTransferEvent,
  ReversePaymentEvent,
  GenerateNextStatementEvent,
  NextAmortizationMonthEvent,
  SetLoanStatusEvent,
  CreateDisbursementEvent,
  CreateForwardPaymentDisbursementEvent,
  CreatePaymentAfterPayoffDisbursementEvent,
  ReverseDisbursementEvent,
  SendGoodbyeEmailEvent,
  SendExternalEmailEvent,
  SendMortgageeLetterEvent,
  SendWelcomeEmailEvent,
  MoveToTransferEvent,
  SendPaymentReminderEmailEvent,
  SendLatePaymentEmailEvent,
  SendGracePastDueEmailEvent,
  SendEmailEvent,
  AddAdditionalBorrowerEvent,
  RemoveAdditionalBorrowerEvent,
  EditBorrowerEvent,
  ChangePrimaryBorrowerEvent,
  SaveStatementPdfEvent,
  SaveDocumentPdfEvent,
  UpdateDocumentTitleEvent,
  UpdateDocumentBorrowerAccessEvent,
  DeleteDocumentEvent,
  SendEscrowCompanyEmailsEvent,
  SendFinalPaymentEmailEvent,
  RegenerateStatementEvent,
  RegenerateStatementPdfEvent,
  RegenerateTransferDocumentsEvent,
  AddAccountCommentEvent, // deprecated
  CreateFeeEvent,
  CancelFeeEvent,
  CreateDrawEvent,
  UpdateAutopaySettingsEvent,
  ReverseDrawEvent,
  EditEscrowFieldsEvent,
  ModifyMaturityDateEvent,
  ModifyPropertyAddressEvent,
  AddAuditLogItemEvent,
  CreateRequestedPayoffEvent,
  CreatePayoffEvent,
  RecordPayoffEvent,
  UpdateAdvanceInterestEvent,
  CreateAdvanceEvent,
  UpdateMonthlyInterestRateEvent,
  GenerateGoodbyeLetterPdfEvent,
  UpdateEscrowSettingEvent,
  UpdateEscrowDueEvent,
  UpdateEscrowPaymentEvent,
  CreateEscrowSettingEvent,
  UsioMarkAsCancelledEvent,
  UsioMarkAsFailedEvent,
  UsioMarkAsProcessedEvent,
  UsioTransactionBatchedEvent,
  UpdateIdsAndRemittanceDataEvent,
  UpdateLoanEvent,
  ConstructionToPermanentEvent,
  CreateWorkoutPlanEvent,
  CancelWorkoutPlanEvent,
  UpdateSentEmailEvent,
  UpdateDelinquentInterestRateEvent,
  UpdateRemittanceSplitEvent,
  WaiveDelinquentInterestEvent,
  DeferDelinquentInterestEvent,
  UpdateBillSettingsEvent,
  MailMonthlyStatementTaskEvent,
]);
export type LoanEvent = z.infer<typeof LoanEvent>;

export const LoanEventWithAuditLog = z.union([
  FlagAccountEvent,
  SetLoanStatusEvent,
  ReverseDisbursementEvent,
  CreationEvent,
  TransferAccountEvent,
  CreatePaymentEvent,
  ReversePaymentEvent,
  GenerateNextStatementEvent,
  SaveDocumentPdfEvent,
  DeleteDocumentEvent,
  UpdateDocumentTitleEvent,
  UpdateDocumentBorrowerAccessEvent,
  CreateDisbursementEvent,
  CreateForwardPaymentDisbursementEvent,
  CreatePaymentAfterPayoffDisbursementEvent,
  MoveToTransferEvent,
  AddAdditionalBorrowerEvent,
  RemoveAdditionalBorrowerEvent,
  ChangePrimaryBorrowerEvent,
  AddAccountCommentEvent, // deprecated
  RegenerateStatementEvent,
  RegenerateStatementPdfEvent,
  CreateFeeEvent,
  CancelFeeEvent,
  EditBorrowerEvent,
  CreateDrawEvent,
  ReverseDrawEvent,
  UpdateAutopaySettingsEvent,
  EditEscrowFieldsEvent,
  ModifyMaturityDateEvent,
  ModifyPropertyAddressEvent,
  AddAuditLogItemEvent,
  CreateRequestedPayoffEvent,
  CreatePayoffEvent,
  RecordPayoffEvent,
  CreateAdvanceEvent,
  UpdateMonthlyInterestRateEvent,
  UpdateEscrowSettingEvent,
  UpdateEscrowDueEvent,
  CreateEscrowSettingEvent,
  CreateFeePaymentEvent,
  UpdateEscrowPaymentEvent,
  UpdateIdsAndRemittanceDataEvent,
  UpdateLoanEvent,
  ConstructionToPermanentEvent,
  CreateWorkoutPlanEvent,
  CancelWorkoutPlanEvent,
  UpdateRemittanceSplitEvent,
  UpdateDelinquentInterestRateEvent,
  WaiveDelinquentInterestEvent,
  DeferDelinquentInterestEvent,
  UpdateBillSettingsEvent,
]);
export type LoanEventWithAuditLog = z.infer<typeof LoanEventWithAuditLog>;
