import MailChecker from 'mailchecker'; // This lib is well maintained and includes huge list of known emails to throw
import { z } from 'zod';

// Followup: remove this regex since mailchecker handles this part of validation
// Email regex taken from https://stackoverflow.com/a/8829363
export const EMAIL_REGEX =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

const BLACKLISTED_EMAILS = [
  'noemail@gmail.com', // lender was using this from LOS system where they didnt have email but something was required
];

export const isValidEmail = (email: string): boolean => {
  const COMPILED_EMAIL_REGEX = new RegExp(EMAIL_REGEX);
  const isValidForm = COMPILED_EMAIL_REGEX.test(email);
  const isBlacklisted = BLACKLISTED_EMAILS.includes(email);
  const isValidMailChecker = MailChecker.isValid(email);

  return isValidForm && !isBlacklisted && isValidMailChecker;
};

const EMAIL_STRING_ERROR_MESSAGE = 'Invalid email provided.';

// use EmailString for required email field
export const EmailString = z.string().refine(
  (val?: string) => {
    if (val == null) return true;
    return isValidEmail(val);
  },
  {
    message: EMAIL_STRING_ERROR_MESSAGE,
  },
);

export const OptionalEmailString = z
  .string()
  .optional()
  .refine(
    (val?: string) => {
      if (val == null || val.length === 0) return true;
      return isValidEmail(val);
    },
    {
      message: EMAIL_STRING_ERROR_MESSAGE,
    },
  );
