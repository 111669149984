import { z } from 'zod';

import { PaymentId, TransferId } from '../BrandedIds';
import { GeneratePayoffReminderEmailType, PaymentLenderEmailTemplateType } from '../emails/Templates';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

const SendLenderBaseEmailSideEffectContext = BaseLoanIdSideEffect.extend({
  emailTemplate: GeneratePayoffReminderEmailType,
});
const SendLenderEmailSideEffect = SendLenderBaseEmailSideEffectContext.extend({
  type: z.literal('sendLenderEmail'),
});

const SendLenderPaymentEmailSideEffectContext = SendLenderBaseEmailSideEffectContext.extend({
  emailTemplate: PaymentLenderEmailTemplateType,
  paymentId: zodBrandedUuid<PaymentId>(),
  transferId: zodBrandedUuid<TransferId>().optional(),
});
const SendLenderPaymentEmailSideEffect = SendLenderPaymentEmailSideEffectContext.extend({
  type: z.literal('sendLenderEmail'),
});
export type SendLenderPaymentEmailSideEffect = z.infer<typeof SendLenderPaymentEmailSideEffect>;

export const SendLenderEmailSideEffectContext = z.union([
  SendLenderBaseEmailSideEffectContext,
  SendLenderPaymentEmailSideEffectContext,
]);
export type SendLenderEmailSideEffect = z.infer<typeof SendLenderEmailSideEffect>;
