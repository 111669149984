import * as z from 'zod';

import { DisbursementId, EscrowDueId, LoanId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const Disbursement = z.object({
  id: zodBrandedUuid<DisbursementId>(),
  loanId: zodBrandedUuid<LoanId>(),
  total: z.number(),
  type: z.enum(['servicingTransfer', 'escrow', 'payoff', 'other', 'forwardPayment', 'paymentAfterPayoff']),
  isReversal: z.boolean(),
  amounts: z.object({
    escrow: z.number(),
    suspense: z.number(),
    principalPrepayment: z.number(),
    principal: z.number().optional().default(0),
    interest: z.number().optional().default(0),
    fee: z.number().optional().default(0),
    reserve: z.number().optional().default(0),
  }),
  date: zodDateOrString,
  recipient: z.string(),
  transactionId: z.string().optional(),
  status: z.literal('PROCESSED'),
  comment: z.string().optional(),
  escrowDueId: zodBrandedUuid<EscrowDueId>().optional(),
});
export type Disbursement = z.infer<typeof Disbursement>;
