import * as z from 'zod';

import { AddressLine, StateAbbreviation, Zipcode } from '../../fields';

export const AddressFields = z.object({
  addressLine1: AddressLine,
  addressLine2: AddressLine.optional(),
  addressLine3: AddressLine.optional(),
  addressLine4: AddressLine.optional(),
  addressLocality: z.string(),
  addressRegion: StateAbbreviation,
  addressPostcode: Zipcode,
  addressCounty: z.string().optional(),
  addressCountry: z.string().optional(),
  address2Line1: AddressLine.optional(),
  address2Line2: AddressLine.optional(),
  address2Line3: AddressLine.optional(),
  address2Line4: AddressLine.optional(),
  address2Locality: z.string().optional(),
  address2Region: StateAbbreviation.optional().or(z.literal('')),
  address2Postcode: Zipcode.optional(),
  address2County: z.string().optional(),
  address2Country: z.string().optional(),
  address3Line1: AddressLine.optional(),
  address3Line2: AddressLine.optional(),
  address3Line3: AddressLine.optional(),
  address3Line4: AddressLine.optional(),
  address3Locality: z.string().optional(),
  address3Region: StateAbbreviation.optional().or(z.literal('')),
  address3Postcode: Zipcode.optional(),
  address3County: z.string().optional(),
  address3Country: z.string().optional(),
  address4Line1: AddressLine.optional(),
  address4Line2: AddressLine.optional(),
  address4Line3: AddressLine.optional(),
  address4Line4: AddressLine.optional(),
  address4Locality: z.string().optional(),
  address4Region: StateAbbreviation.optional().or(z.literal('')),
  address4Postcode: Zipcode.optional(),
  address4County: z.string().optional(),
  address4Country: z.string().optional(),
  address5Line1: AddressLine.optional(),
  address5Line2: AddressLine.optional(),
  address5Line3: AddressLine.optional(),
  address5Line4: AddressLine.optional(),
  address5Locality: z.string().optional(),
  address5Region: StateAbbreviation.optional().or(z.literal('')),
  address5Postcode: Zipcode.optional(),
  address5County: z.string().optional(),
  address5Country: z.string().optional(),
  address6Line1: AddressLine.optional(),
  address6Line2: AddressLine.optional(),
  address6Line3: AddressLine.optional(),
  address6Line4: AddressLine.optional(),
  address6Locality: z.string().optional(),
  address6Region: StateAbbreviation.optional().or(z.literal('')),
  address6Postcode: Zipcode.optional(),
  address6County: z.string().optional(),
  address6Country: z.string().optional(),
  address7Line1: AddressLine.optional(),
  address7Line2: AddressLine.optional(),
  address7Line3: AddressLine.optional(),
  address7Line4: AddressLine.optional(),
  address7Locality: z.string().optional(),
  address7Region: StateAbbreviation.optional().or(z.literal('')),
  address7Postcode: Zipcode.optional(),
  address7County: z.string().optional(),
  address7Country: z.string().optional(),
  address8Line1: AddressLine.optional(),
  address8Line2: AddressLine.optional(),
  address8Line3: AddressLine.optional(),
  address8Line4: AddressLine.optional(),
  address8Locality: z.string().optional(),
  address8Region: StateAbbreviation.optional().or(z.literal('')),
  address8Postcode: Zipcode.optional(),
  address8County: z.string().optional(),
  address8Country: z.string().optional(),
  address9Line1: AddressLine.optional(),
  address9Line2: AddressLine.optional(),
  address9Line3: AddressLine.optional(),
  address9Line4: AddressLine.optional(),
  address9Locality: z.string().optional(),
  address9Region: StateAbbreviation.optional().or(z.literal('')),
  address9Postcode: Zipcode.optional(),
  address9County: z.string().optional(),
  address9Country: z.string().optional(),
  address10Line1: AddressLine.optional(),
  address10Line2: AddressLine.optional(),
  address10Line3: AddressLine.optional(),
  address10Line4: AddressLine.optional(),
  address10Locality: z.string().optional(),
  address10Region: StateAbbreviation.optional().or(z.literal('')),
  address10Postcode: Zipcode.optional(),
  address10County: z.string().optional(),
  address10Country: z.string().optional(),
});
