import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

import s from './Button.module.scss';

type ButtonLinkProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'danger';
  underline?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export const ButtonLink = ({
  variant = 'primary',
  underline = false,
  children,
  className,
  disabled,
  loading,
  ...props
}: ButtonLinkProps) => {
  return (
    <button
      className={classNames(s.button, 'fw-normal', className, { 'u-pointer-none': disabled || loading })}
      disabled={disabled || loading}
      {...props}
    >
      <span
        className={classNames('d-flex align-items-center gap-2', {
          'u-color-pine4 hover:u-color-willow': variant === 'primary',
          'u-color-red1 hover:u-color-red2': variant === 'danger',
          'text-decoration-underline': underline,
        })}
      >
        {children}

        {loading && (
          <div className={s.loaderInline}>
            <Spinner animation="border" role="status" />
          </div>
        )}
      </span>
    </button>
  );
};
