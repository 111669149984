import { isEmpty, isUndefined } from 'lodash';
import * as z from 'zod';

import { StrictAddress } from '../../Address';
import { LosId } from '../../BrandedIds';
import { OptionalPhoneNumber } from '../../Phone';
import { typedSafeParse, zodBrandedString } from '../../utils';
import { CamelizeEnum } from '../../zod';
import { OptionalEmailString } from '../email';
import {
  AddressLine,
  DateString,
  PositiveFloat,
  PositiveInteger,
  PositiveMonetaryValue,
  SocialSecurityNumber,
  StateAbbreviation,
  Zipcode,
} from '../fields';
import { unsafeCreateCastedSchema } from '../utils';
import { LoanPurposeInput, OccupancyTypeInput } from './CreateLoanRow';

const ProductType = z.enum(['conv', 'va', 'fha', 'usda', 'pih', 'bridge', 'businessPurpose', 'jumbo']);

export const hasPrimaryBorrowerAddressFields = (data: z.infer<typeof PrimaryBorrowerAddressSchema>) =>
  [
    data.primaryBorrowerMailingAddressLine1,
    data.primaryBorrowerMailingAddressLine2,
    data.primaryBorrowerMailingAddressLine3,
    data.primaryBorrowerMailingAddressLine4,
    data.primaryBorrowerMailingAddressLocality,
    data.primaryBorrowerMailingAddressPostcode,
    data.primaryBorrowerMailingAddressRegion,
  ].some((value) => !isEmpty(value));

const PrimaryBorrowerAddressSchema = z
  .object({
    primaryBorrowerMailingAddressLine1: AddressLine.optional(),
    primaryBorrowerMailingAddressLine2: AddressLine.optional(),
    primaryBorrowerMailingAddressLine3: AddressLine.optional(),
    primaryBorrowerMailingAddressLine4: AddressLine.optional(),
    primaryBorrowerMailingAddressLocality: AddressLine.optional(),
    primaryBorrowerMailingAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    // Custom refinements with strings that could be blank are funky,
    // the author of Zod had recommendation to use `.or(z.literal(''))`
    // https://github.com/colinhacks/zod/issues/310#issuecomment-794533682
    primaryBorrowerMailingAddressPostcode: Zipcode.optional().or(z.literal('')),
  })
  // Typically refinements only run when the base schema passes validation,
  // so if we want these refinements to run in cases where other parts of
  // the base schema validation might fail it's suggested to split up your
  // schema and run these refinements on a subset of items so they are likely
  // to run AND we avoid the problem of having to cast to `any` and loose all
  // type safety
  .superRefine((data, ctx) => {
    // If any mailing address field is populated, then require
    // full valid address
    const hasAddressField = hasPrimaryBorrowerAddressFields(data);

    // No address information was provided, exit validation early
    if (!hasAddressField) return;

    const address = typedSafeParse(StrictAddress, {
      line1: data.primaryBorrowerMailingAddressLine1 ?? '',
      line2: data.primaryBorrowerMailingAddressLine2,
      line3: data.primaryBorrowerMailingAddressLine3,
      line4: data.primaryBorrowerMailingAddressLine4,
      locality: data.primaryBorrowerMailingAddressLocality ?? '',
      region: data.primaryBorrowerMailingAddressRegion ?? '',
      postcode: data.primaryBorrowerMailingAddressPostcode ?? '',
      country: 'USA',
    });

    // Has valid address, exit validation early
    if (address.success) return;

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['primaryBorrowerMailingAddressLine1'],
      message: 'Missing full primary borrower mailing address',
    });
  });

const LoanStatusSchema = z
  .object({
    loanStatus: z.literal('active').optional(),
    firstPaymentCollectedDate: DateString.optional(),
    principalPurchased: PositiveFloat.optional(),
    servicerPurchaseDate: DateString.optional(),
    servicerPurchasedEscrowBalance: PositiveFloat.optional(),
    servicerPurchasedReserveBalance: PositiveFloat.optional(),
    initialReserveBalance: PositiveFloat.optional(),
  })
  .superRefine((data, ctx) => {
    if (data.loanStatus === 'active') {
      if (isUndefined(data.firstPaymentCollectedDate))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['firstPaymentCollectedDate'],
          message: 'Required',
        });
      if (isUndefined(data.principalPurchased))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['principalPurchased'],
          message: 'Required',
        });
      if (isUndefined(data.servicerPurchaseDate))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['servicerPurchaseDate'],
          message: 'Required',
        });
    }
  });

const EntitySchema = z.object({
  entityCompanyId: z.string().optional(),
  entityName: z.string().optional(),
  entityEin: z.string().optional(),
  entityAddressLine1: AddressLine.optional(),
  entityAddressLine2: AddressLine.optional(),
  entityAddressLocality: AddressLine.optional(),
  entityAddressRegion: StateAbbreviation.optional().or(z.literal('')),
  entityAddressPostcode: Zipcode.optional().or(z.literal('')),
  entityAddressCountry: z.literal('USA').optional(),
  entityPhone: OptionalPhoneNumber,
  entityEmail: OptionalEmailString,
});

// Conditional refinement approach mentioned here
// https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005
export const UpdateLoanRow = z
  .object({
    loanId: zodBrandedString<LosId>(),
    primaryBorrowerSsn: SocialSecurityNumber.optional(),
    productType: CamelizeEnum(ProductType).optional(),
    loanPurpose: CamelizeEnum(LoanPurposeInput).optional(),
    occupancyType: CamelizeEnum(OccupancyTypeInput).optional(),
    mortgageInsuranceAmount: PositiveMonetaryValue.optional(),
    initialMortgageInsuranceAmount: PositiveMonetaryValue.optional(),
    initialDiscountPointsAmount: PositiveMonetaryValue.optional(),
    loanLegalDescription: z.string().optional(),
    numberOfProperties: PositiveInteger.optional(),
    currentOutstandingBalance: PositiveFloat.optional(),
    escrowBalance: PositiveFloat.optional(),
    interestCollected: PositiveFloat.optional(),
    principalPrepayment: PositiveFloat.optional(),
    suspenseBalance: PositiveFloat.optional(),
  })
  .and(PrimaryBorrowerAddressSchema)
  .and(LoanStatusSchema)
  .and(EntitySchema);

export type UpdateLoanRow = z.infer<typeof UpdateLoanRow>;

export const UpdateLoanRowRefinements = unsafeCreateCastedSchema(UpdateLoanRow);
