import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { WorkoutPlanInput } from '../loan/WorkoutPlan';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateWorkoutPlanEvent = BaseLoanEvent.extend({
  type: z.literal('createWorkoutPlan'),
  data: WorkoutPlanInput,
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
});
export type CreateWorkoutPlanEvent = z.infer<typeof CreateWorkoutPlanEvent>;
