import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';

import s from './Button.module.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  onClick?: (e: any) => unknown | Promise<unknown>;
  children: React.ReactNode;
};
export const Button = ({
  size = 'md',
  variant = 'primary',
  disabled = false,
  loading = false,
  icon,
  onClick,
  children,
  className,
  ...props
}: ButtonProps) => (
  <button
    onClick={loading ? () => {} : onClick}
    type="button"
    className={classNames(s.button, className, {
      'pt-3 pb-3 ps-5 pe-5 u-fs-3': variant !== 'tertiary' && size === 'lg',
      'pt-2 pb-2 ps-3 pe-3': variant !== 'tertiary' && size === 'md',
      'pt-1 pb-1 ps-3 pe-3 u-fs-2': variant !== 'tertiary' && size === 'sm',
      [s.buttonPrimary]: variant === 'primary',
      [s.buttonSecondary]: variant === 'secondary',
      [s.buttonTertiary]: variant === 'tertiary',
      'u-pointer-none': disabled || loading,
    })}
    disabled={disabled || loading}
    {...props}
  >
    <div className="d-flex align-items-center gap-2" style={{ opacity: loading ? 0 : 1 }}>
      {icon && <>{icon}</>}
      <span>{children}</span>
    </div>

    {loading && (
      <div className={s.loader}>
        <Spinner animation="border" role="status" />
      </div>
    )}
  </button>
);
