import { z } from 'zod';

import { Address } from '../Address';
import { PropertyId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';

export const Property = z.object({
  id: zodBrandedUuid<PropertyId>(),
  address: Address,
  isReleased: z.boolean(),
});

export type Property = z.infer<typeof Property>;
