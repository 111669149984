import { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { apolloConfig } from '@willow/graphql-iso/src/app';
import { Auth0Domain } from '@willow/shared-iso';
import {
  ApolloCacheConfig,
  AuthorizedProvider,
  getAppContext,
  InitLoader,
  NamedMemo,
  UniversalLoader,
} from '@willow/shared-web';
import { Container, Spinner } from '@willow/shared-web/bootstrap';

const App = lazy(() => import('./App').then(({ App }) => ({ default: App })));

const getAuth0ClientId = () => {
  if (APP_CONTEXT === 'production') {
    return 'vKLZi4fBg4xrrlYCSTZoKvOXBy0T25XE';
  }
  if (APP_CONTEXT === 'staging' || APP_CONTEXT === 'flex') {
    return 'WQJBZ2tw90B0UYSJp8x5JybaqvWOcF2Y';
  }
  return 'sk4bunf492FR0tuz4L0XORrqnc514QHO';
};

const APP_CONTEXT = getAppContext();
const AUTH0_DOMAIN = Auth0Domain.getCustomDomainForApp(APP_CONTEXT);
const AUTH0_CLIENT_ID = getAuth0ClientId();
const AUTH0_AUDIENCE = `https://${Auth0Domain.getForApp(APP_CONTEXT)}/api/v2/`;
const GRAPHQL_ENDPOINT =
  process.env.NODE_ENV === 'production' ? '/api/app/graphql' : 'http://localhost:8000/api/app/graphql';
const APP_CACHE_CONFIG: ApolloCacheConfig = {
  typePolicies: {
    Borrower: {
      keyFields: ['id', 'companyId'],
    },
  },
  possibleTypes: apolloConfig.possibleTypes,
};

export const AdminApp = NamedMemo('AdminApp', () => {
  return (
    <UniversalLoader loader={(isLoading) => <InitLoader visible={isLoading} />}>
      <AuthorizedProvider
        auth0Domain={AUTH0_DOMAIN}
        auth0ClientId={AUTH0_CLIENT_ID}
        auth0Audience={AUTH0_AUDIENCE}
        graphqlEndpoint={GRAPHQL_ENDPOINT}
        apolloCacheConfig={APP_CACHE_CONFIG}
      >
        <Router>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Suspense
              fallback={
                <Container className="spinner-container">
                  <Spinner animation="border" className="loading-spinner" />
                </Container>
              }
            >
              <App appContextEnv={APP_CONTEXT} />
            </Suspense>
          </QueryParamProvider>
        </Router>
      </AuthorizedProvider>
    </UniversalLoader>
  );
});
