/*
  This component for adding bank accounts is shared between the app + portal.
  We are using Plaid for the user to link their account and then sending that information to Dwolla.

  When a user initiates a microdeposit bank hookup, we display slightly different text.
  Currently, we only allow adding one bank at a time, so the microdeposit either needs to succeed, fail, or expire before the state updates.
*/
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { PlaidLinkError, PlaidLinkOnExit, PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';
import { toast } from 'react-toastify';

import {
  CompanyDwollaBank,
  PostPlaidBankToDwollaDocument as AppPostPlaidBankToDwollaDocument,
  RemovePlaidTokenDocument as AppRemovePlaidTokenDocument,
  SaveMicroDepositTokenDocument as AppSaveMicroDepositTokenDocument,
} from '@willow/graphql-iso/src/app';
import {
  DwollaBank,
  PostPlaidBankToDwollaDocument as PortalPostPlaidBankToDwollaDocument,
  RemovePlaidTokenDocument as PortalRemovePlaidTokenDocument,
  SaveMicroDepositTokenDocument as PortalSaveMicroDepositTokenDocument,
} from '@willow/graphql-iso/src/portal';
import { CompanyId } from '@willow/types-iso';

import { Loader } from '../Loader/Loader';
import { Modal } from '../Modal/Modal';
import { NamedMemo } from '../NamedMemo';

import './AddBankAccount.scss';

interface Props {
  linkToken: string; // token for Plaid
  microDepositVerification: boolean;
  banks: DwollaBank[] | CompanyDwollaBank[];
  companyName?: string;
  companyId?: CompanyId;
  // after adding account: need to refetch dwolla token so you can add another account without refreshing the page
  onAddBankAccount?: () => void;
  onIsBusy?: (isBusy: boolean) => void; // two busy methods for letting app + portal parents know when this is busy
  setIsBusy?: (value: boolean) => void;
  isLenderAccount?: boolean; // used to differentiate styles + button text
}

export const AddBankAccount = NamedMemo<Props>(
  'AddBankAccount',
  ({
    linkToken,
    microDepositVerification,
    banks,
    companyName,
    companyId,
    onAddBankAccount,
    onIsBusy,
    setIsBusy,
    isLenderAccount,
  }) => {
    const [portalPostBank] = useMutation(PortalPostPlaidBankToDwollaDocument);
    const [appPostBank] = useMutation(AppPostPlaidBankToDwollaDocument);
    const [portalSaveMicroDepositToken] = useMutation(PortalSaveMicroDepositTokenDocument);
    const [appSaveMicroDepositToken] = useMutation(AppSaveMicroDepositTokenDocument);
    const [portalRemovePlaidToken] = useMutation(PortalRemovePlaidTokenDocument);
    const [appRemovePlaidToken] = useMutation(AppRemovePlaidTokenDocument);

    // When a bank is added in the portal, the borrower needs to agree to our terms before
    // we can add their bank. This is where we stash pending plaid details while the borrower agrees.
    const [pendingPlaidData, setPendingPlaidData] = useState<Parameters<PlaidLinkOnSuccess>>();
    const [isPlaidBusy, setIsPlaidBusy] = useState(false);
    const [isTOSBusy, setIsTOSBusy] = useState(false);

    const getPlaidErrorMessage = useCallback((error: PlaidLinkError): string => {
      // Plaid error docs: https://plaid.com/sdocs/errors/
      const defaultMessage = 'Something went wrong with linking your bank account. Please try again.';
      if (!error) {
        return defaultMessage;
      }

      // Plaid sends through a display error message in some cases. Some of the messages are not clear / formatted correctly, so err on displaying the default message instead of relying on theirs
      switch (error.error_code) {
        case 'INVALID_CREDENTIALS':
        case 'INVALID_ACCOUNT_NUMBER':
          return 'The provided bank credentials were invalid and we could not link your bank account. Please try again.';
        case 'USER_INPUT_TIMEOUT':
          return 'Plaid timed out. Please try again.';
        case 'PLANNED_MAINTENANCE':
          return 'Plaid is temporarily unavailable due to planned maintenance. Please try again later.';
        case 'INCORRECT_DEPOSIT_AMOUNTS':
        case 'TOO_MANY_VERIFICATION_ATTEMPTS':
          return 'The microdeposit amounts entered were incorrect. If entered incorrectly three times, you must restart and re-link your bank account.';
        case 'NO_AUTH_ACCOUNTS':
          return `No eligible accounts: We didn't find any checking or savings accounts at this institution. Please try linking another institution.`;
        case 'ITEM_LOCKED':
        case 'INVALID_MFA':
        case 'MFA_NOT_SUPPORTED':
        case 'ITEM_NOT_SUPPORTED':
        case 'NO_ACCOUNTS':
        case 'USER_SETUP_REQUIRED':
        case 'INSTITUTION_DOWN':
        case 'INSTITUTION_NO_LONGER_SUPPORTED':
        case 'INSTITUTION_NOT_AVAILABLE':
        case 'INSTITUTION_NOT_RESPONDING':
          return error.display_message;
        default:
          return defaultMessage;
      }
    }, []);

    const addBankAccount = useCallback<PlaidLinkOnSuccess>(
      async (public_token, metadata) => {
        const account = metadata.accounts[0]; // Account Select: Enabled for one account
        if (account.verification_status && account.verification_status !== 'manually_verified') {
          if (companyId) {
            await appSaveMicroDepositToken({
              variables: { companyId, publicToken: public_token, accountId: account.id },
            });
          } else {
            await portalSaveMicroDepositToken({ variables: { publicToken: public_token, accountId: account.id } });
          }
        } else {
          try {
            if (companyId) {
              await appPostBank({
                variables: {
                  companyId,
                  publicToken: public_token,
                  accountId: account.id,
                  accountNickname: account.name,
                },
              });
            } else {
              await portalPostBank({
                variables: {
                  publicToken: public_token,
                  accountId: account.id,
                  accountNickname: account.name,
                },
              });
            }
          } catch (err: any) {
            Sentry.captureException(err);

            const error = JSON.parse(err?.message);
            switch (error?.code) {
              case 'DuplicateResource':
                toast.error('This bank account already exists. Please try again with a new bank account.');
                break;
              case 'InvalidResourceState':
                toast.error(
                  'There is an issue connecting this account. Please contact your bank for more information.',
                );
                break;
              default:
                toast.error('Something went wrong adding your bank account, please try again');
                break;
            }

            setIsPlaidBusy(false);
            setIsTOSBusy(false);
          }
        }

        onAddBankAccount && onAddBankAccount(); // Reload link token
      },
      [appPostBank, portalPostBank, onAddBankAccount, companyId, appSaveMicroDepositToken, portalSaveMicroDepositToken],
    );

    const onConfirmPortalTOS = useCallback(
      async (e: any) => {
        e.preventDefault();

        setIsTOSBusy(true);
        try {
          if (pendingPlaidData) {
            await addBankAccount(...pendingPlaidData);
            await setPendingPlaidData(undefined);
          }
        } catch {
          toast.error('Something went wrong with accepting payment terms. Please try again.');
        } finally {
          setIsPlaidBusy(false);
          setIsTOSBusy(false);
        }
      },
      [pendingPlaidData, setPendingPlaidData, addBankAccount],
    );

    const onCancelPortalTOS = useCallback(() => {
      setPendingPlaidData(undefined);
      setIsPlaidBusy(false);
    }, [setPendingPlaidData]);

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
      async (...args) => {
        // Portal users must accept Dwolla's terms of service
        // before we can add their bank account.
        if (companyId) {
          // Lender
          await addBankAccount(...args);
          setIsPlaidBusy(false);
        } else {
          // Borrower
          await setPendingPlaidData(args);
        }
      },
      [companyId, addBankAccount, setPendingPlaidData],
    );

    const onExit = useCallback<PlaidLinkOnExit>(
      // The user exited Plaid without connecting an account.
      // Log the details to Sentry.
      async (err, metadata) => {
        if (setIsBusy) setIsBusy(false);
        if (onIsBusy) onIsBusy(false);
        setIsPlaidBusy(false);

        Sentry.addBreadcrumb({
          category: 'plaid',
          message: `Plaid Request IDs:
            \nlink_session_id: ${metadata.link_session_id}
            \nrequest_id: ${metadata.request_id}`,
        });
        Sentry.addBreadcrumb({
          category: 'plaid',
          message: `Plaid Metadata:
            \ninstitution: ${JSON.stringify(metadata.institution)}
            \nstatus: ${JSON.stringify(metadata.status)}`,
        });

        if (err) {
          Sentry.captureMessage(`Borrower encountered Plaid error: ${JSON.stringify(err)}`, 'error');
          if (err.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
            if (companyId) {
              await appRemovePlaidToken({ variables: { companyId } });
            } else {
              await portalRemovePlaidToken();
            }
          }
          onAddBankAccount && onAddBankAccount(); // Reload link token

          toast.error(getPlaidErrorMessage(err));
        } else {
          Sentry.captureMessage('Borrower exited Plaid without linking account', 'warning');
        }
      },
      [
        onAddBankAccount,
        setIsBusy,
        onIsBusy,
        appRemovePlaidToken,
        companyId,
        getPlaidErrorMessage,
        portalRemovePlaidToken,
      ],
    );

    const onEvent = useCallback((eventName: string) => {
      Sentry.addBreadcrumb({
        category: 'plaid',
        message: `Event: ${eventName}`,
      });
    }, []);

    const { open: openPlaid } = usePlaidLink({
      token: linkToken,
      onSuccess,
      onExit,
      onEvent,
    });

    const onAddBtnClick = useCallback(() => {
      setIsPlaidBusy(true);
      openPlaid();
    }, [openPlaid]);

    useEffect(() => {
      if (setIsBusy) setIsBusy(false);
      if (onIsBusy) onIsBusy(false);
    }, [banks, onIsBusy, setIsBusy]);

    return (
      <div
        className={classnames(['add-bank-account'], {
          'add-bank-account__empty': !banks.length,
          'add-bank-account__busy': isPlaidBusy,
          'add-bank-account--lender': isLenderAccount,
        })}
      >
        {!banks.length && companyName && !microDepositVerification && !isLenderAccount && (
          <h3 className="add-bank-account__title">
            <button type="button" onClick={onAddBtnClick} className="add-bank-account__title-btn">
              Securely connect your bank account
            </button>{' '}
            to submit your mortgage payment.
          </h3>
        )}

        {microDepositVerification && (
          <h3 className="add-bank-account__title">
            Your micro-deposit authorization has been received. Expect a notification when two small deposits have been
            deposited to your account. Please verify those amounts upon receipt.
          </h3>
        )}

        <button type="button" onClick={onAddBtnClick} data-ghost="portal-loan--add-bank-account-button">
          {microDepositVerification ? (
            <>Verify my account</>
          ) : (
            <>
              {isLenderAccount ? (
                <>Connect account</>
              ) : (
                <>{banks.length ? <>+ Add another account</> : <>Connect my account</>}</>
              )}
            </>
          )}
        </button>

        {isPlaidBusy && (
          <div className="add-bank-account__loader">
            <Loader />
          </div>
        )}

        <Modal showModal={!!pendingPlaidData} onClose={onCancelPortalTOS}>
          <div className="add-bank-account__confirm-modal">
            <h2>Agree to Payment Terms</h2>
            <p>
              I agree that future payments to {companyName} will be processed by the Dwolla payment system from my
              selected account. In order to cancel this authorization, I will change my payment settings within my{' '}
              {companyName} account.
            </p>
            <button
              type="button"
              onClick={onConfirmPortalTOS}
              className={classnames(['add-bank-account__confirm-modal__btn'], {
                'add-bank-account__confirm-modal__btn--busy': isTOSBusy,
              })}
            >
              <span>Agree and Continue</span>

              {isTOSBusy && <Loader />}
            </button>
          </div>
        </Modal>
      </div>
    );
  },
);
