
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Attribution": [
      "DelinquentInterestAttribution",
      "DrawInterestAttribution",
      "StandardInterestAttribution"
    ],
    "AuditLogEvent": [
      "LoanAuditLogEvent",
      "TaskAuditLogEvent"
    ],
    "AuditLogEvent2": [
      "LoanAuditLogEvent2",
      "TaskAuditLogEvent2"
    ],
    "AuthorizedContact": [
      "BuilderAuthorizedContact",
      "ThirdPartyAuthorizedContact"
    ],
    "AutopaySettings": [
      "DwollaAutopaySettings",
      "UsioAutopaySettings"
    ],
    "BorrowerDocumentAccess": [
      "AllBorrowerDocumentAccess",
      "WhiteListedBorrowerDocumentAccess"
    ],
    "Charge": [
      "Advance",
      "Fee"
    ],
    "Document": [
      "DefermentWorkoutPlanDocument",
      "DelinquentInterestAppliedDocument",
      "EmailCopyDocument",
      "EscrowAnalysisDocument",
      "Form1098Document",
      "GeneralDocument",
      "MonthlyStatementDocument",
      "PayoffDocument",
      "TransferDocument",
      "UploadDocument"
    ],
    "EscrowDue": [
      "InsuranceEscrowDue",
      "TaxEscrowDue"
    ],
    "EscrowSetting": [
      "InsuranceEscrowSetting",
      "TaxEscrowSetting"
    ],
    "NonPerformanceData": [
      "ForeclosureNonPerformanceData",
      "GeneralNonPerformanceData"
    ],
    "PaidOffRecord": [
      "TrackedPaidOffRecord",
      "UntrackedPaidOffRecord"
    ],
    "Payer": [
      "AuthorizedContactPayer",
      "BorrowerPayer"
    ],
    "PaymentToPaymentDue": [
      "AdvanceBreakdown",
      "FeeBreakdown",
      "PayoffBreakdown",
      "PrincipalPrepaymentBreakdown",
      "StatementBreakdown",
      "SuspenseBreakdown"
    ],
    "TaskCollectedData": [
      "Chapter7StepAttorneyReliefCollectedData",
      "Chapter7StepCreditorMeetingCollectedData",
      "Chapter7StepFilingStayCollectedData",
      "Chapter7StepHearingDateCollectedData",
      "Chapter7StepHearingOutcomeCollectedData",
      "Chapter13StepAttorneyReferralCollectedData",
      "Chapter13StepCreditorMeetingCollectedData",
      "Chapter13StepFilingStayCollectedData",
      "Chapter13StepPlanInPlaceCollectedData",
      "EmptyCollectedData",
      "ForeclosureStepDemandLettersCollectedData",
      "HomeSaleCollectedData",
      "JudicialStateCollectedData",
      "NonJudicialStateCollectedData",
      "ReferForeclosureAttorneyCollectedData",
      "ReoStepContractStatusCollectedData",
      "ReoStepCounterOfferCollectedData",
      "ReoStepEvictionOfOccupiedPropertyCollectedData",
      "ReoStepOfferEvaluationsCollectedData",
      "ReoStepOrderInspectionCollectedData",
      "ReoStepOrderRepairsCollectedData",
      "ReoStepPropertyListingCollectedData",
      "ReoStepPropertyReductionCollectedData"
    ],
    "TaskContext": [
      "DocumentTaskContext",
      "EmptyTaskContext",
      "EscrowAnalysisTaskContext",
      "EscrowDueTaskContext",
      "EventTaskContext",
      "MailBundleTaskContext",
      "MonthlyStatementTaskContext",
      "PaymentTaskContext",
      "PayoffTaskContext",
      "TransferDocumentTaskContext"
    ],
    "WorkoutPlan": [
      "DefermentWorkoutPlan",
      "RepaymentWorkoutPlan"
    ]
  }
};
      export default result;
    