import { z } from 'zod';

export const CompanyLoanControlSettings = z.object({
  loanNoteCategoriesRequired: z.boolean().optional(), // TODO migration followup required
});
export type CompanyLoanControlSettings = z.infer<typeof CompanyLoanControlSettings>;

export const DEFAULT_LOAN_CONTROL_SETTINGS: CompanyLoanControlSettings = {
  loanNoteCategoriesRequired: false,
};
