import { z } from 'zod';

import { EscrowPaymentHistoryItemId, PaymentId, QcDocumentId } from '../BrandedIds';
import { Actor } from '../events';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const EscrowPaymentHistoryItemStatus = z.enum(['active', 'cancelled']);

export type EscrowPaymentHistoryItemStatus = z.infer<typeof EscrowPaymentHistoryItemStatus>;

export const EscrowPaymentHistoryBase = z.object({
  id: zodBrandedUuid<EscrowPaymentHistoryItemId>(),
  createdAt: zodDateOrString,
  newMonthlyAmount: z.number(),
  actor: Actor,
  status: EscrowPaymentHistoryItemStatus.optional(), // TODO make required
});

export const EscrowPaymentHistoryInitial = EscrowPaymentHistoryBase.extend({
  type: z.literal('initial'),
});

export type EscrowPaymentHistoryInitial = z.infer<typeof EscrowPaymentHistoryInitial>;

export const EscrowPaymentHistoryManualUpdate = EscrowPaymentHistoryBase.extend({
  type: z.literal('manualUpdate'),
});

export type EscrowPaymentHistoryManualUpdate = z.infer<typeof EscrowPaymentHistoryManualUpdate>;

export const EscrowPaymentHistoryConstructionToPermanent = EscrowPaymentHistoryBase.extend({
  type: z.literal('constructionToPermanent'),
});

export type EscrowPaymentHistoryConstructionToPermanent = z.infer<typeof EscrowPaymentHistoryConstructionToPermanent>;

export const EscrowPaymentHistoryShortagePayment = EscrowPaymentHistoryBase.extend({
  type: z.literal('shortagePayment'),
  escrowShortageAnalysisId: zodBrandedUuid<EscrowPaymentHistoryItemId>(),
  paymentId: zodBrandedUuid<PaymentId>(),
});

export type EscrowPaymentHistoryShortagePayment = z.infer<typeof EscrowPaymentHistoryShortagePayment>;

export const EscrowPaymentHistoryAnalysis = EscrowPaymentHistoryBase.extend({
  type: z.literal('analysis'),
  shortageAmount: z.number(),
  newMonthlyAmountIfShortagePaidUpfront: z.number().optional(),
  effectiveDate: zodDateOrString,
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
});

export type EscrowPaymentHistoryAnalysis = z.infer<typeof EscrowPaymentHistoryAnalysis>;

export const EscrowPaymentHistoryItem = z.discriminatedUnion('type', [
  EscrowPaymentHistoryInitial,
  EscrowPaymentHistoryManualUpdate,
  EscrowPaymentHistoryShortagePayment,
  EscrowPaymentHistoryAnalysis,
  EscrowPaymentHistoryConstructionToPermanent,
]);

export type EscrowPaymentHistoryItem = z.infer<typeof EscrowPaymentHistoryItem>;

export const isEscrowPaymentHistoryItemInitial = (
  item: EscrowPaymentHistoryItem,
): item is EscrowPaymentHistoryInitial => item.type === 'initial';

export const isEscrowPaymentHistoryItemManualUpdate = (
  item: EscrowPaymentHistoryItem,
): item is EscrowPaymentHistoryManualUpdate => item.type === 'manualUpdate';

export const isEscrowPaymentHistoryItemAnalysis = (
  item: EscrowPaymentHistoryItem,
): item is EscrowPaymentHistoryAnalysis => item.type === 'analysis';

export const isEscrowPaymentHistoryItemShortagePayment = (
  item: EscrowPaymentHistoryItem,
): item is EscrowPaymentHistoryShortagePayment => item.type === 'shortagePayment';

export const isEscrowPaymentHistoryItemConstructionToPermanent = (
  item: EscrowPaymentHistoryItem,
): item is EscrowPaymentHistoryConstructionToPermanent => item.type === 'constructionToPermanent';
