import * as z from 'zod';

import { PaymentAddress } from '../Address';
import { EscrowSettingId, PropertyId } from '../BrandedIds';
import { OptionalPhoneNumber } from '../Phone';
import { DueMonthDay, zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { OptionalEmailString } from '../validations/email';
import { PositiveMonetaryValue } from '../validations/fields';

export const PaymentCadence = z.enum(['ANNUAL', 'SEMI-ANNUAL', 'QUARTERLY']);

export type PaymentCadence = z.infer<typeof PaymentCadence>;

export const PaymentCadenceEnum = z.preprocess((val) => {
  if (typeof val === 'string') {
    return val.toUpperCase();
  }
  return val;
}, PaymentCadence);

export type PaymentCadenceEnum = z.infer<typeof PaymentCadenceEnum>;

export const EscrowSettingStatus = z.enum(['ACTIVE', 'INACTIVE']);

export const BaseEscrowSetting = z.object({
  id: zodBrandedUuid<EscrowSettingId>(),
  propertyId: zodBrandedUuid<PropertyId>(),
  escrowed: z.boolean(),
  status: EscrowSettingStatus,
  amount: PositiveMonetaryValue,
  payee: z.object({
    name: z.string(),
    address: PaymentAddress.optional(),
  }),
  propertyIndex: z.number().optional(), // Used to pair with the right property
  updatedAt: zodDateOrString,
});

export const TaxEscrowType = z.enum(['COUNTY_TAX', 'CITY_TAX', 'SCHOOL_TAX', 'OTHER_TAX']);

export type TaxEscrowType = z.infer<typeof TaxEscrowType>;

export const TaxEscrowSetting = BaseEscrowSetting.extend({
  type: z.literal('taxEscrowSetting'),
  dueDates: z.array(DueMonthDay).optional(),
  taxType: TaxEscrowType,
  apn: z.string().optional(),
});

export type TaxEscrowSetting = z.infer<typeof TaxEscrowSetting>;

export const InsuranceEscrowSetting = BaseEscrowSetting.extend({
  type: z.literal('insuranceEscrowSetting'),
  insuranceName: z.string().optional(),
  agentName: z.string().optional(),
  agentEmail: OptionalEmailString,
  agentPhone: OptionalPhoneNumber,
  policyNumber: z.string(),
  policyStartDate: zodDateOrString,
  policyEndDate: zodDateOrString,
});

export type InsuranceEscrowSetting = z.infer<typeof InsuranceEscrowSetting>;

export const EscrowSetting = z.discriminatedUnion('type', [TaxEscrowSetting, InsuranceEscrowSetting]);

export type EscrowSetting = z.infer<typeof EscrowSetting>;

export const TaxEscrowSettingInput = TaxEscrowSetting.omit({ propertyId: true, updatedAt: true }).extend({
  county: z.string().optional(),
});

export type TaxEscrowSettingInput = z.infer<typeof TaxEscrowSettingInput>;

export const InsuranceEscrowSettingInput = InsuranceEscrowSetting.omit({ propertyId: true, updatedAt: true });

export type InsuranceEscrowSettingInput = z.infer<typeof InsuranceEscrowSettingInput>;

export const EscrowSettingInput = z.discriminatedUnion('type', [TaxEscrowSettingInput, InsuranceEscrowSettingInput]);

export type EscrowSettingInput = z.infer<typeof EscrowSettingInput>;
