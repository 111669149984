import * as z from 'zod';

import { AddressFields } from '../sharedFields/AddressFields';
import { AuthorizedContactFields } from './AuthorizedContactFields';
import { CoborrowerFields } from './CoborrowerFields';
import { InsuranceFields, MultipleAddressesInsuranceFields } from './InsuranceFields';
import { RemittanceFields } from './RemittanceFields';
import { MultipleAddressesTaxFields, TaxFields } from './TaxFields';

// By doing the merge here and only exporting a single type
// it appears to get rid of the following TS error:
//
// Recursive type: Type instantiation is excessively deep and possibly infinite.
export const CreateLoanAddressFields = z.object({}).merge(AddressFields);

export type CreateLoanAddressFields = z.infer<typeof CreateLoanAddressFields>;

export const CreateLoanMultipleAddressFields = z
  .object({})
  .merge(MultipleAddressesInsuranceFields)
  .merge(MultipleAddressesTaxFields);
export type CreateLoanMultipleAddressFields = z.infer<typeof CreateLoanMultipleAddressFields>;

export const CreateLoanFields = z
  .object({})
  .merge(CoborrowerFields)
  .merge(AuthorizedContactFields)
  .merge(InsuranceFields)
  .merge(RemittanceFields)
  .merge(TaxFields);
export type CreateLoanFields = z.infer<typeof CreateLoanFields>;
