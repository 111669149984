import { z } from 'zod';

import { CreateLoanRow } from '@willow/types-iso';

import { getParsedFields } from '../CreateLoanCrossFieldParser';

export const validateArmFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'loanType',
    'amortizationType',
    'armIndex',
    'armMarginPercent',
    'armMarginDecimal',
    'armRateAdjustmentFrequency',
    'armFixedTermMonths',
    'armFirstRateChangeDate',
    'armMaxIncreasePerPeriodPercent',
    'armMaxIncreasePerPeriodDecimal',
    'armMinIncreasePerPeriodPercent',
    'armMinIncreasePerPeriodDecimal',
    'armMaxIncreaseForFirstPeriodPercent',
    'armMaxIncreaseForFirstPeriodDecimal',
    'armMinIncreaseForFirstPeriodPercent',
    'armMinIncreaseForFirstPeriodDecimal',
  ]);
  if (!parsed || (parsed.amortizationType !== 'arm' && parsed.loanType !== 'arm')) return;

  const {
    armIndex,
    armMarginPercent,
    armMarginDecimal,
    armRateAdjustmentFrequency,
    armFixedTermMonths,
    armFirstRateChangeDate,
    armMaxIncreasePerPeriodDecimal,
    armMaxIncreasePerPeriodPercent,
    armMinIncreasePerPeriodPercent,
    armMinIncreasePerPeriodDecimal,
    armMaxIncreaseForFirstPeriodPercent,
    armMaxIncreaseForFirstPeriodDecimal,
    armMinIncreaseForFirstPeriodPercent,
    armMinIncreaseForFirstPeriodDecimal,
  } = parsed;

  if (armIndex == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing required field for ARM: armIndex.',
    });
  }

  if (armRateAdjustmentFrequency == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing required field for ARM: armRateAdjustmentFrequency.',
    });
  }

  if (armMarginPercent == null && armMarginDecimal == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing the margin for ARM. Please provide either armMarginPercent or armMarginDecimal.',
    });
  }

  if (armFixedTermMonths == null && armFirstRateChangeDate == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Missing the first effective change date for ARM. Please provide either armFixedTermMonths or armFirstRateChangeDate.',
    });
  }

  if (
    armMinIncreaseForFirstPeriodPercent &&
    armMaxIncreaseForFirstPeriodPercent &&
    armMinIncreaseForFirstPeriodPercent > armMaxIncreaseForFirstPeriodPercent
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'armMinIncreaseForFirstPeriodPercent cannot be greater than armMaxIncreaseForFirstPeriodPercent',
    });
  }

  if (
    armMinIncreaseForFirstPeriodDecimal &&
    armMaxIncreaseForFirstPeriodDecimal &&
    armMinIncreaseForFirstPeriodDecimal > armMaxIncreaseForFirstPeriodDecimal
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'armMinIncreaseForFirstPeriodDecimal cannot be greater than armMaxIncreaseForFirstPeriodDecimal',
    });
  }

  if (
    armMinIncreasePerPeriodPercent &&
    armMaxIncreasePerPeriodPercent &&
    armMinIncreasePerPeriodPercent > armMaxIncreasePerPeriodPercent
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'armMinIncreasePerPeriodPercent cannot be greater than armMaxIncreasePerPeriodPercent',
    });
  }

  if (
    armMinIncreasePerPeriodDecimal &&
    armMaxIncreasePerPeriodDecimal &&
    armMinIncreasePerPeriodDecimal > armMaxIncreasePerPeriodDecimal
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'armMinIncreasePerPeriodDecimal cannot be greater than armMaxIncreasePerPeriodDecimal',
    });
  }
};
