import * as z from 'zod';

import { Address } from '../Address';
import { LosId } from '../BrandedIds';
import { zodDateOrString } from '../utils/Zod';
import { TransferLoanRow } from '../validations/loan/TransferLoanRow';

// THINGS TO DO WITH THE RAW TRANSFER ROW
// First, take the initial object parser
// from the spreadsheet uploader or API — ie, TransferLoanRow.
// Then...
// * Turn date strings into Date
// * Turn ids into branded IDs
export const TransferLoanRowData = TransferLoanRow.extend({
  loanId: z.string().transform((id) => id as LosId),
  transferEffectiveDate: zodDateOrString,
  transferSoldDate: zodDateOrString.optional(),
});
export type TransferLoanRowData = z.infer<typeof TransferLoanRowData>;

export const LoanTransferData = z.object({
  servicerName: z.string(),
  servicerLoanNumber: z.string(),
  transferEffectiveDate: zodDateOrString,
  paymentAddress: Address,
  correspondenceAddress: Address.optional(),
  mortgageeClauseCompanyName: z.string().optional(),
  mortgageeClauseAddress: Address.optional(),
  phone: z.string(),
  sentGoodbyeEmail: z.boolean().optional(),
  beginningOfYearPrincipalBalance: z.number().optional(),
  loanOfficerName: z.string().optional(),
  loanOfficerEmail: z.string().optional(),
  servicerWebsite: z.string().optional(),
  servicerHours1: z.string().optional(),
  servicerHours2: z.string().optional(),
  servicerHours3: z.string().optional(),
  transferPrincipalBalance: z.number().optional(),
  transferEscrowBalance: z.number().optional(),
  transferReserveBalance: z.number().optional(),
  transferSoldDate: zodDateOrString.optional(),
});
export type LoanTransferData = z.infer<typeof LoanTransferData>;
