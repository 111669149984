import * as z from 'zod';

import { FeeId, PaymentDueId, PaymentId, PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { Payoff } from './Payoffs';

export const PaymentBreakdownTypeStatement = z.literal('statement');
export const PaymentBreakdownTypeSuspense = z.literal('suspense');
export const PaymentBreakdownTypePrincipalPrepayment = z.literal('principalPrepayment');
export const PaymentBreakdownTypeFee = z.literal('fee');
export const PaymentBreakdownTypeAdvance = z.literal('advance');
export const PaymentBreakdownTypePayoff = z.literal('payoff');

export const PaymentBreakdownType = z.union([
  PaymentBreakdownTypeStatement,
  PaymentBreakdownTypeSuspense,
  PaymentBreakdownTypePrincipalPrepayment,
  PaymentBreakdownTypeFee,
  PaymentBreakdownTypeAdvance,
  PaymentBreakdownTypePayoff,
]);
export type PaymentBreakdownType = z.infer<typeof PaymentBreakdownType>;

const BaseBreakdown = z.object({
  paymentId: zodBrandedUuid<PaymentId>(),
  amount: z.number(),
  expectedPayment: z.number(),
});

const StatementBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypeStatement,
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  additionalPrincipal: z.number(),
  additionalEscrow: z.number(),
  suspense: z.number(),
  reserve: z.number().optional(),
});
export type StatementBreakdown = z.infer<typeof StatementBreakdown>;

const SuspenseBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypeSuspense,
  suspense: z.number(),
});
export type SuspenseBreakdown = z.infer<typeof SuspenseBreakdown>;

const PrincipalPrepaymentBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypePrincipalPrepayment,
  additionalPrincipal: z.number(),
  reserve: z.number().optional(),
});
export type PrincipalPrepaymentBreakdown = z.infer<typeof PrincipalPrepaymentBreakdown>;

const FeeBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypeFee,
  feeId: zodBrandedUuid<FeeId>(),
});
export type FeeBreakdown = z.infer<typeof FeeBreakdown>;

const AdvanceBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypeAdvance,
  feeId: zodBrandedUuid<FeeId>(),
  advance: z.number(),
  advanceInterest: z.number(),
});
export type AdvanceBreakdown = z.infer<typeof AdvanceBreakdown>;

const PayoffBreakdown = BaseBreakdown.extend({
  type: PaymentBreakdownTypePayoff,
  payoffId: zodBrandedUuid<PayoffId>(),
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  suspense: z.number(),
  reserve: z.number(),
  fees: z.number(),
});
export type PayoffBreakdown = z.infer<typeof PayoffBreakdown>;

export const PaymentBreakdown = z.discriminatedUnion('type', [
  StatementBreakdown,
  SuspenseBreakdown,
  PrincipalPrepaymentBreakdown,
  FeeBreakdown,
  AdvanceBreakdown,
  PayoffBreakdown,
]);
export type PaymentBreakdown = z.infer<typeof PaymentBreakdown>;

export const ChargeBreakdown = z.discriminatedUnion('type', [FeeBreakdown, AdvanceBreakdown]);
export type ChargeBreakdown = z.infer<typeof ChargeBreakdown>;

export function isStatementBreakdown(item: PaymentBreakdown): item is StatementBreakdown {
  return item.type === 'statement';
}
export function isSuspenseBreakdown(item: PaymentBreakdown): item is SuspenseBreakdown {
  return item.type === 'suspense';
}
export function isPrincipalPrepaymentBreakdown(item: PaymentBreakdown): item is PrincipalPrepaymentBreakdown {
  return item.type === 'principalPrepayment';
}
export function isFeeBreakdown(item: PaymentBreakdown): item is FeeBreakdown {
  return item.type === 'fee';
}

export function isAdvanceBreakdown(item: PaymentBreakdown): item is AdvanceBreakdown {
  return item.type === 'advance';
}

export function isChargeBreakdown(item: PaymentBreakdown): item is ChargeBreakdown {
  return item.type === 'fee' || item.type === 'advance';
}

export function isPayoffBreakdown(item: PaymentBreakdown): item is PayoffBreakdown {
  return item.type === 'payoff';
}

export function isFullPayoffBreakdown(item: PaymentBreakdown, payoffs: Payoff[]): item is PayoffBreakdown {
  const fullPayoffIds = payoffs.filter((p) => p.isPartial !== true).map((p) => p.id);
  return item.type === 'payoff' && fullPayoffIds.includes(item.payoffId);
}
