import * as z from 'zod';

import { PositiveFloat } from '../../fields';

export const RemittanceFields = z.object({
  investorRemittanceRate: PositiveFloat.optional(),
  investor2RemittanceRate: PositiveFloat.optional(),
  servicerRemittanceRate: PositiveFloat.optional(),
  investorOwnershipPercent: PositiveFloat.optional(),
  investor2OwnershipPercent: PositiveFloat.optional(),
});
