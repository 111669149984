import { z } from 'zod';

import { zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ModifyMaturityDateEvent = BaseLoanEvent.extend({
  type: z.literal('modifyMaturityDate'),
  maturityDate: zodDateOrString,
});
export type ModifyMaturityDateEvent = z.infer<typeof ModifyMaturityDateEvent>;
