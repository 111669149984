import * as z from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { Disbursement } from './Disbursement';
import { PaymentBreakdown } from './PaymentBreakdown';
import { Payment } from './Payments';

const InterestPaidTotals = z.object({
  interest: z.number(),
  lateFees: z.number(),
  subsidizedInterest: z.number(),
  netInterest: z.number(),
});
export type InterestPaidTotals = z.infer<typeof InterestPaidTotals>;

const InterestPaidPaymentBreakdown = z.object({
  date: z.date(),
  breakdown: PaymentBreakdown,
  totals: InterestPaidTotals,
});
export type InterestPaidPaymentBreakdown = z.infer<typeof InterestPaidPaymentBreakdown>;

export const InterestPaidPayment = z.object({
  id: zodBrandedUuid<PaymentId>(),
  totals: InterestPaidTotals,
  payment: Payment,
  breakdowns: z.array(InterestPaidPaymentBreakdown),
  forwardedDisbursement: Disbursement.optional(),
});
export type InterestPaidPayment = z.infer<typeof InterestPaidPayment>;

const InterestPaidAtClosing = z.object({
  date: z.date(),
  netInitialInterestBalance: z.number(),
  netDiscountPointsAmount: z.number(),
  originalBoardingFields: z.object({
    boardingDate: z.date(),
    fundingDate: z.date(),
    initialInterestBalance: z.number().optional(),
    discountPointsAmount: z.number().optional(),
  }),
  totals: InterestPaidTotals,
});
export type InterestPaidAtClosing = z.infer<typeof InterestPaidAtClosing>;

export const InterestPaidInYear = z.object({
  year: z.number(),
  totals: InterestPaidTotals,
  payments: z.array(InterestPaidPayment),
  interestPaidAtClosing: InterestPaidAtClosing.optional(),
});
export type InterestPaidInYear = z.infer<typeof InterestPaidInYear>;
