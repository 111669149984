import { range } from 'lodash';
import { z } from 'zod';

import { CreateLoanRow, isValidEmail } from '@willow/types-iso';

import { getParsedFields } from '../CreateLoanCrossFieldParser';

export const validateEmailAddresses: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  // Follow enhancement: validate against most email addresses (ie escrow company agent)
  const CO_BORROWER_EMAILS = range(1, 11)
    .map((n) => [`coBorrower${n}Email`] as (keyof CreateLoanRow)[])
    .flat();
  const ESCROW_AGENT_EMAILS = range(1, 4)
    .map((n) => [`escrow${n}AgentEmail`] as (keyof CreateLoanRow)[])
    .flat();
  const AUTH_CONTACT_EMAILS = ['builderEmail'] as (keyof CreateLoanRow)[];

  const parsed = getParsedFields(data, [
    'primaryBorrowerEmail',
    ...CO_BORROWER_EMAILS,
    ...ESCROW_AGENT_EMAILS,
    ...AUTH_CONTACT_EMAILS,
  ]);
  if (!parsed) return;

  for (const [key, value] of Object.entries(parsed)) {
    const email = value as string;
    if (email && !isValidEmail(email)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid email address',
        path: [key],
      });
    }
  }
};
