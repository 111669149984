import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import { Checkbox, DateString, InterestRate, PositiveFloat, PositiveInteger } from '../fields';
import { FlatfileMonetaryValue } from '../flatfileFields';
import { InsuranceFields } from './createLoanFields/InsuranceFields';
import { MailingAddress } from './createLoanFields/MailingAddressFields';
import { TaxFields } from './createLoanFields/TaxFields';
import { AddressFields } from './sharedFields/AddressFields';

export const ConstructionToPermanentRow = z
  .object({
    loanId: zodBrandedString<LosId>(),
    constructionLoanModificationEffectiveDate: DateString,
    loanTermMonths: PositiveInteger,
    maturityDate: DateString,
    firstPaymentPrincipalAndInterest: FlatfileMonetaryValue,
    initialEscrowBalance: FlatfileMonetaryValue,
    monthlyPaymentEscrow: FlatfileMonetaryValue,
    modifiedPrincipalAmount: FlatfileMonetaryValue.optional(),
    modifiedPrincipalPrepayment: FlatfileMonetaryValue.optional(),
    modifiedInterestRatePercent: PositiveFloat.optional(),
    modifiedInterestRateDecimal: InterestRate.optional(),
    modifiedAmortizationType: z.literal('fixed').optional(),

    initialMortgageInsuranceAmount: FlatfileMonetaryValue.optional(),
    monthlyPaymentMi: FlatfileMonetaryValue.optional(),
    isEscrowed: Checkbox.optional(),
  })
  .merge(MailingAddress)
  .merge(AddressFields.partial())
  .merge(TaxFields)
  .merge(InsuranceFields);

export type ConstructionToPermanentRow = z.infer<typeof ConstructionToPermanentRow>;

export const CONSTRUCTION_TO_PERMANENT_ROW_REQUIRED_FIELDS = Object.keys(ConstructionToPermanentRow.shape).filter(
  (key) => !ConstructionToPermanentRow.shape[key as keyof typeof ConstructionToPermanentRow.shape].isOptional(),
);
