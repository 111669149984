import * as z from 'zod';

import { CompanyId, CompanyReportId, LoanOwnerId, LosId, PoolId, UserId } from './BrandedIds';
import { LoanStatus } from './loan/LoanStatus';
import { IsoDateFilter } from './loanTransactionFilter/LoanTransactionFilter';
import { zodBrandedString, zodBrandedUuid } from './utils/Zod';

export interface CompanyReport {
  id: CompanyReportId;
  userId: UserId;
  companyId: CompanyId;
  title: string;
  location: string;
  loanCount: number;
  recordCount: number;
  type: CompanyReportType;
  recurring: boolean;
  fromDate?: Date;
  toDate?: Date;
  exactDate?: Date;
  createdAt: Date;
  updatedAt: Date;
  sftpSentStatus?: SftpSentStatus;
  sftpError?: string;
}

export enum SftpSentStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  ERROR = 'ERROR',
}

export enum CompanyReportType {
  CASH_MANAGEMENT = 'CASH_MANAGEMENT',
  INVESTOR_REMITTANCE = 'INVESTOR_REMITTANCE',
  INVESTOR_REMITTANCE_NEW = 'INVESTOR_REMITTANCE_NEW',
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  LOAN_NOTES = 'LOAN_NOTES',
  SERVICING_TRANSFER = 'SERVICING_TRANSFER',
  LOANS_BILLED = 'LOANS_BILLED',
  PAYMENT_SUMMARY = 'PAYMENT_SUMMARY',
  LOAN_PIPELINE = 'LOAN_PIPELINE',
  ESCROW_DISBURSEMENTS = 'ESCROW_DISBURSEMENTS',
  AUDIT_LOG = 'AUDIT_LOG',
  AUTHORIZED_ACH_BATCH = 'AUTHORIZED_ACH_BATCH',
  LOAN_PRODUCTIVITY = 'LOAN_PRODUCTIVITY',

  // Lower reports
  COMPILED_ACCRUALS_AND_PAYMENTS = 'COMPILED_ACCRUALS_AND_PAYMENTS',
  LOAN_TRANSFER_DOCUMENTS = 'LOAN_TRANSFER_DOCUMENTS',
  LOS_BALANCE_AND_TRANSACTIONS = 'LOS_BALANCE_AND_TRANSACTIONS',

  // Kensie Mae / Interline reports
  KENSIE_MAE_PAYMENT = 'KENSIE_MAE_PAYMENT',
  KENSIE_MAE_ESCROW_DISBURSEMENT = 'KENSIE_MAE_ESCROW_DISBURSEMENT',
  KENSIE_MAE_PRINCIPAL_PREPAYMENT = 'KENSIE_MAE_PRINCIPAL_PREPAYMENT',
}

export const DateFilterField = z.enum(['transferEffectiveDate', 'transferSoldDate', 'paymentProcessedDate']);

export type DateFilterField = z.infer<typeof DateFilterField>;

export const CompanyReportFilters = z
  .object({
    losIds: z.array(z.custom<LosId>()),
    poolIds: z.array(zodBrandedUuid<PoolId>()),
    ownerIds: z.array(zodBrandedString<LoanOwnerId>()),
    servicerNames: z.array(z.string()).optional(),
    userIds: z.array(zodBrandedUuid<UserId>()).optional(),
    fullIds: z.array(z.string()),
    capitalPartnerIds: z.array(z.string()),
    otherId1s: z.array(z.string()),
    previousServicerIds: z.array(z.string()),
    loanStatus: LoanStatus.optional(),
    dateFilterField: DateFilterField.optional(),
  })
  .merge(IsoDateFilter);

export type CompanyReportFilters = z.infer<typeof CompanyReportFilters>;

export type CreateReportResponse = {
  reportFile: Buffer;
  meta: {
    recordCount: number;
    loanCount: number;
    minDate: Date;
    maxDate: Date;
  };
};
