import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RegenerateStatementEvent = BaseLoanEvent.extend({
  type: z.literal('regenerateStatement'),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  includeAllDrawsInMonth: z.boolean().optional(),
  disableEmail: z.boolean().optional(),
});
export type RegenerateStatementEvent = z.infer<typeof RegenerateStatementEvent>;
