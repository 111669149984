import { round } from 'lodash';

const DEFAULT_DECIMAL_PLACES = 3;

export const decimalToPercentageNumber = (
  decimalAmount: number,
  decimalPlaces: number = DEFAULT_DECIMAL_PLACES,
): number => round(decimalAmount * 100, decimalPlaces);

export const decimalToPercentage = (decimalAmount: number, decimalPlaces: number = DEFAULT_DECIMAL_PLACES): string =>
  `${decimalToPercentageNumber(decimalAmount, decimalPlaces).toFixed(decimalPlaces)}%`;
