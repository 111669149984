import { z } from 'zod';

import { Address } from './Address';
import { BorrowerId } from './BrandedIds';

export const BorrowerName = z
  .object({
    firstName: z.string(),
    middleName: z.string().optional(),
    lastName: z.string().optional(),
  })
  .strict();
export type BorrowerName = z.infer<typeof BorrowerName>;

export interface BorrowerCompanySettings {
  emailConsent: boolean;
  emailNotificationsEnabled: boolean;
  paperNotificationsEnabled: boolean;
  mailingAddress?: Address;
}

export interface Borrower extends BaseBorrowerAndPortalUser {
  id: BorrowerId;
  email?: string;
  ssn?: string;
  settings: BorrowerCompanySettings;
}

export interface BaseBorrowerAndPortalUser {
  phone?: string;
  name: string;
  raw: BorrowerName;
}

const BorrowerEmailConsentActor = z.union([z.literal('lender'), z.literal('borrower'), z.literal('internal')]);
export type BorrowerEmailConsentActor = z.infer<typeof BorrowerEmailConsentActor>;

export const BorrowerEmailConsent = z.object({
  emailConsent: z.boolean(),
  actor: BorrowerEmailConsentActor,
  borrowerIp: z.string().optional(),
});
export type BorrowerEmailConsent = z.infer<typeof BorrowerEmailConsent>;

export const DEFAULT_BORROWER_EMAIL_CONSENT: BorrowerEmailConsent = {
  emailConsent: true,
  actor: 'internal',
} as const;
