import { range } from 'lodash';
import { DateTime } from 'luxon';
import * as z from 'zod';

import { convertStringToDate, CreateLoanRow } from '@willow/types-iso';

import { validateAuthorizedContactFields } from './crossFieldParsers/validateAuthorizedContactFields';

const firstCollectedDateIsInPast: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const { firstCollectedPaymentDate } = data;

  if (!firstCollectedPaymentDate) {
    return;
  }

  if (convertStringToDate(firstCollectedPaymentDate) < new Date()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Because `firstCollectedPaymentDate` is in the past, the loan will be marked as past due.',
      path: ['firstCollectedPaymentDate'],
    });
  }
};

const stopEmailOrPayments: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const { stopEmails, stopPayments } = data;

  if (stopEmails) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Because `stopEmails` is set to true, the loan will be automatically flagged.',
      path: ['stopEmails'],
    });
  }

  if (stopPayments) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Because `stopPayments` is set to true, the loan will be automatically flagged.',
      path: ['stopPayments'],
    });
  }
};

const agedLoanHasBeginningOfYearPrincipalBalance: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const { acquisitionDate, fundingDate, firstCollectedPaymentDate, beginningOfYearPrincipalBalance } = data;

  if (firstCollectedPaymentDate) {
    const lenderOwnershipStartDate = acquisitionDate || fundingDate;
    const lenderTookOwnershipInYearPriorToWillowServicing =
      DateTime.fromJSDate(new Date(lenderOwnershipStartDate)).year <
      DateTime.fromJSDate(new Date(firstCollectedPaymentDate)).year;

    // Lender has been servicing this loan since a previous year, so we need to know the starting year principal balance for tax reporting
    if (lenderTookOwnershipInYearPriorToWillowServicing && !beginningOfYearPrincipalBalance) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Loan that has been active since before start of boarding year should include `beginningOfYearPrincipalBalance` for tax reporting.',
      });
    }
  }
};

const escrowInsuranceHasPolicyDates: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  for (const n of range(1, 4)) {
    if (
      // @ts-ignore
      data[`insurance${n}IsEscrowed`] === true ||
      // @ts-ignore
      (data[`insurance${n}Amount`] != null && data[`insurance${n}Amount`] > 0) ||
      // @ts-ignore
      data[`insurance${n}CompanyName`] != null ||
      // @ts-ignore
      data[`insurance${n}PolicyNumber`] != null
    ) {
      const missingFields: string[] = [];

      for (const key of [`insurance${n}PolicyStart`, `insurance${n}PolicyEnd`, `insurance${n}PolicyNumber`]) {
        // @ts-ignore
        if (data[key] == null) {
          missingFields.push(key);
        }
      }

      if (missingFields.length) {
        const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Insurance policy ${n} is missing ${
            missingFields.length > 1 ? 'fields' : 'a field'
          } and will not be added to the loan: ${missingFieldsString}`,
        });
      }
    }
  }
};

const escrowCompaniesHavePolicyNumber: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  for (const n of range(1, 4)) {
    // @ts-ignore
    if (data[`escrow${n}CompanyName`] != null && data[`escrow${n}PolicyNumber`] == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Escrow company ${n} is missing \`escrow${n}PolicyNumber\` and will not be added to the loan`,
      });
    }
  }
};

export const CreateLoanWarningParser = z
  .any()
  .superRefine(firstCollectedDateIsInPast)
  .superRefine(stopEmailOrPayments)
  .superRefine(agedLoanHasBeginningOfYearPrincipalBalance)
  .superRefine(escrowInsuranceHasPolicyDates)
  .superRefine(escrowCompaniesHavePolicyNumber)
  .superRefine(validateAuthorizedContactFields);
