import { get, isEmpty, mapValues } from 'lodash';
import { Except } from 'type-fest';
import * as z from 'zod';

import { Address, AddressInput } from '@willow/graphql-iso/src/app';
import { Address as ZodAddress, StrictAddress, trimAndStrip } from '@willow/types-iso';

export const getDefaultAddress = (): ZodAddress => ({
  line1: '',
  line2: '',
  line3: '',
  line4: '',
  locality: '',
  region: '',
  postcode: '',
  country: '',
});

export const EMPTY_ADDRESS = z.object({
  line1: z.undefined(),
  line2: z.undefined(),
  line3: z.undefined(),
  line4: z.undefined(),
  locality: z.undefined(),
  region: z.undefined(),
  postcode: z.undefined(),
});

// Stripping country field from address validator since we aren't exposing the field to frontend forms & hardcode this to "US" if left blank
export const VALID_ADDRESS = z.union([EMPTY_ADDRESS, StrictAddress.partial({ country: true })]);
export type ValidAddress = z.infer<typeof VALID_ADDRESS>;
const ADDRESS_FORM_FIELDS = z.preprocess((data: any) => mapValues(data, trimAndStrip), VALID_ADDRESS);

export const getAddressInput = (
  address?: Except<Address, '__typename', { requireExactProps: true }>,
): AddressInput | undefined => {
  if (!address) return undefined;

  if (!ADDRESS_FORM_FIELDS.safeParse(address).success) {
    return undefined;
  }
  if (isEmpty(address)) return undefined; // only add country if address fields are filled in

  return { ...address, country: address?.country || 'USA' };
};

const REQUIRED_ADDRESS_FIELDS: (keyof Address)[] = ['line1', 'locality', 'region', 'postcode'];
export const superRefineAddress =
  (pathToAddress: string[] = []): z.SuperRefinement<any> =>
  (data, ctx) => {
    // See if any required address fields are filled
    const hasAddress = REQUIRED_ADDRESS_FIELDS.map((key) => get(data, [...pathToAddress, key])).some((v) => !!v);
    if (!hasAddress) {
      return;
    }

    // See if all other required address fields are also filled. If not, add errors to the zod object.
    for (const key of REQUIRED_ADDRESS_FIELDS) {
      const path = [...pathToAddress, key];
      const value = get(data, path);
      if (value == null || value === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
          path,
        });
      }
    }
  };
